/* eslint-disable react-hooks/exhaustive-deps */
import {
  AD_COL_CLASSES,
  ANALYTICS_EVENTS,
  AUC_COLOR,
  DEFAULT_TRAIT_STYLE,
  HIPHEN_GREEN,
  THEME,
} from "../../constants";
import { Button, Col, Offcanvas, Row } from "reactstrap";
import Joyride, { EVENTS } from "react-joyride";
import {
  requestFeaturePresignedUrl,
  requestSetCompletedTour,
} from "../../services/backendRequests";
import {
  selectExcludedGroupsSet,
  selectFeaturesFilteredOnExperimentAndLayer,
  selectFilteredFeatures,
  selectNumericalPropertiesMetricsAndAggregatedFeatures,
} from "../../selectors/resultMap";
import { setAucData, setSelectedTrait } from "../../actions/resultMap";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ADVANCED_FILTERING_STEPS } from "../../guidedTours/guidedTours";
import { AdvancedFiltering } from "./components/advancedFiltering/AdvancedFiltering";
import { ComponentTitle } from "../components/componentTitle/componentTitle";
import { ControlSection } from "./components/controlSection/controlSection";
import { EvolutionChart } from "./components/charts/EvolutionChart";
import { ExpandedFocusView } from "./components/expandedFocusView/expandedFocusView";
import { ExportSection } from "./components/exportSection/exportSection";
import { FocusList } from "./components/focusList/focusList";
import { HistogramChart } from "./components/charts/HistogramChart";
import { MultipleChart } from "./components/charts/MultipleChart";
import { PlotRatingModal } from "./components/plotRating/PlotRatingModal";
import PropTypes from "prop-types";
import { PropertyName } from "../../components/PropertyName/PropertyName";
import ReactSwitch from "react-switch";
import { Timeline } from "./components/timeline/timeline";
import { TraitSelector } from "./components/traitSelector/traitSelector";
import { VarietyThumbnail } from "./components/varietyThumbnail/varietyThumbnail";
import Worldmap from "../../trialDataviz/worldmap";
import { formatDate } from "../utils";
import { hasRatePlotsRole } from "../../users/rolesUtil";
import { mean } from "./utils";
import moment from "moment";
import no_image from "../../static/img/microplot_placeholder.png";
import { refreshUser } from "../../actions/user";
import { useTracking } from "../../analytics";

/*
 * Breeder report template:
 * Template: implementation of dashboard for a specific need. Defines behaviours and positioning of components
 * Breeder template is a powerful implementation to rank genotypes based on their measured traits for each date, with the
 * objective of facilitating process of taking decisions for breeders
 * exclude microplots too far away from normal distribution (ground effect, etc.)
 * analyze evolution of carefully selected genotypes, compare them overtime
 * display performance on every calculated trait
 * export results in several formats
 */
export const BreederReportTemplate = ({
  externalTrialSelected,
  onTrialChange,
  refetch,
  setExternalTrialSelected,
  sortedUserTrials,
}) => {
  const dispatch = useDispatch();
  const filteredDatesRef = useRef();

  const [aucModeChecked, setAucModeChecked] = useState(false);
  const [expandFocusView, setExpandFocusView] = useState(false);
  const [mapExpanded, setMapExpanded] = useState(false);
  const [propertyFilter, setPropertyFilter] = useState("");
  const [highlightedProperty, setHighlightedProperty] = useState(null);
  const [aucDatesIndexes, setAucDatesIndexes] = useState([0, 1]);

  const user = useSelector((state) => state.user);
  const [
    features,
    featuresByDate,
    trial,
    refreshing,
    selectedTrait,
    focusedVarieties,
    visualizedVarieties,
    favoriteVarieties,
    selectedPlotId,
    selectedContract,
    selectedDate,
  ] = useSelector(({ resultMap }) => [
    resultMap.features,
    resultMap.featuresByDate,
    resultMap.trial,
    resultMap.refreshing,
    resultMap.selectedTrait,
    resultMap.focusedVarieties,
    resultMap.visualizedVarieties,
    resultMap.favoriteVarieties,
    resultMap.selectedPlotId,
    resultMap.selectedContract,
    resultMap.trial_date,
  ]);

  const currentContract = useMemo(() => {
    return trial != null
      ? user.contracts.find(({ id }) => id === trial?.contract_id)
      : null;
  }, [user.contracts, trial]);

  const plotRatingConfig = useMemo(
    () => currentContract?.plot_rating_config ?? [],
    [currentContract]
  );

  const userCanRatePlots =
    plotRatingConfig.length > 0 && hasRatePlotsRole(currentContract.roles);

  // Construct traits by date
  const propertiesByDate = useMemo(() => {
    const propertiesByDate = {};
    featuresByDate?.forEach((feature) => {
      const date = feature["date"];
      if (!Object.hasOwn(propertiesByDate, date))
        propertiesByDate[date] = Object.keys(feature.properties);
    });

    return Object.entries(propertiesByDate).map(([date, traits]) => ({
      date,
      traits,
    }));
  }, [featuresByDate]);

  // Keep only dates where selected trait is available.
  const filteredDates = useMemo(() => {
    const trait = (selectedTrait.baseTrait ?? selectedTrait).technical_name;

    return propertiesByDate
      .filter(({ traits }) => traits.includes(trait))
      .map(({ date }) => date)
      .sort();
  }, [selectedTrait, propertiesByDate]);
  // Reset aucDatesIndexes on `filteredDates` change
  if (filteredDates !== filteredDatesRef.current) {
    filteredDatesRef.current = filteredDates;
    setAucDatesIndexes([0, 1]);
  }

  // AUC
  const aucData = useSelector((state) => state.resultMap.aucData);
  const { trackEvent } = useTracking();

  const onAucToggle = () => {
    setAucModeChecked(!aucModeChecked);
    trackEvent(ANALYTICS_EVENTS.AUC_MODE);
  };

  const aucDates = useMemo(
    () => [
      filteredDates[aucDatesIndexes[0]],
      filteredDates[aucDatesIndexes[1]],
    ],
    [aucDatesIndexes, filteredDates]
  );

  const computeAUC = (features, trait) => {
    const aucDataCopy = JSON.parse(JSON.stringify(aucData));
    const groups = [...new Set(features.map((element) => element.group))];
    const traitLabel = `${trait.technical_name}|${moment(aucDates[0]).format(
      "MM-DD"
    )} → ${moment(aucDates[1]).format("MM-DD")}`;
    if (
      !aucDataCopy.traits.find(
        ({ technical_name }) => technical_name === traitLabel
      )
    ) {
      const newTrait = {
        name: traitLabel,
        technical_name: traitLabel,
        isAddon: true,
        baseTrait: trait,
        style: DEFAULT_TRAIT_STYLE,
        traitGroup: trait.traitGroup,
        aucDates,
      };
      aucDataCopy.traits.push(newTrait);
      dispatch(setSelectedTrait(newTrait));
      setAucModeChecked(false);
    }

    groups.forEach((group) => {
      // calculate auc for each group
      const groupFeatures = features.filter(
        (feature) =>
          feature.group === group && feature.properties[trait?.technical_name]
      );

      const dates = [...new Set(groupFeatures.map(({ date }) => date))].filter(
        (date) => {
          return moment(date).isBetween(...aucDates, undefined, "[]");
        }
      );

      let area = 0; // Initially set area to zero
      const days = Math.abs(
        moment(dates.at(-1)).diff(moment(dates[0]), "days")
      );
      for (let i = 0; i < dates.length - 1; i++) {
        const values0 = groupFeatures
          .filter(({ date }) => date === dates[i])
          .map((gf) => gf.properties[trait?.technical_name]);

        const values1 = groupFeatures
          .filter(({ date }) => date === dates[i + 1])
          .map((gf) => gf.properties[trait?.technical_name]);

        area +=
          (mean(values0) + mean(values1)) *
          Math.abs(moment(dates[i]).diff(moment(dates[i + 1]), "days")) *
          0.5;
      }

      groupFeatures.forEach((gf) => {
        if (!aucDataCopy.featureData[gf.id])
          aucDataCopy.featureData[gf.id] = {};
        aucDataCopy.featureData[gf.id][traitLabel] = area / days;
      });
    });

    dispatchSetAucData(aucDataCopy);
  };

  const selectedFeature = useMemo(() => {
    return selectedPlotId
      ? features.find(({ id }) => id === selectedPlotId)
      : null;
  }, [selectedPlotId, features]);

  const scopeTrait = useMemo(
    () => (selectedTrait.isAddon ? selectedTrait.baseTrait : selectedTrait),
    [selectedTrait]
  );

  // FILTERS
  // Features filtered on experiments, modalities and layer
  const preFilteredFeatures = useSelector(
    selectFeaturesFilteredOnExperimentAndLayer
  );
  const filteredFeatures = useSelector(selectFilteredFeatures);

  const dispatchSetAucData = (aucData) => {
    dispatch(setAucData(aucData));
  };

  const [aggregatedPrefilteredFeatures, numericalPropertiesMetrics] =
    useSelector(selectNumericalPropertiesMetricsAndAggregatedFeatures);
  const excludedGroups = useSelector(selectExcludedGroupsSet);

  const groups = useMemo(
    () =>
      aggregatedPrefilteredFeatures
        .filter(({ group }) => !excludedGroups.has(group))
        .map(({ group }) => group),
    [aggregatedPrefilteredFeatures, excludedGroups]
  );

  // OTHER
  let mapExpTimeout;

  const getColor = useCallback(
    (gen) => {
      if (!focusedVarieties.includes(gen)) return "transparent";
      if (!groups.includes(gen)) return "lightgray"; // gray out when group is filtered

      return THEME.extendedPalette[
        focusedVarieties.indexOf(gen) % THEME.extendedPalette.length
      ];
    },
    [focusedVarieties, groups]
  );

  // Images
  const [storedImages, setStoredImages] = useState({});

  const getImage = async (feature, layer) => {
    const { date, id } = feature;
    const key = `${id} ${date} ${layer}`;
    if (storedImages[key]) return storedImages[key];
    try {
      const image = await requestFeaturePresignedUrl(
        trial,
        feature,
        date,
        user
      );
      setStoredImages({ ...storedImages, [key]: image });
      return image;
    } catch {
      return { url: no_image, blank: true };
    }
  };

  // Plot rating
  const [plotRatingModalIsOpen, setPlotRatingModalOpen] = useState(false);

  const focusedPlots = useMemo(
    () =>
      filteredFeatures
        .filter(({ group }) => focusedVarieties.includes(group))
        // Sorting elements based on their variety so they can be iterated through in the same order they are displayed
        .sort(
          (a, b) =>
            focusedVarieties.indexOf(a.group) -
            focusedVarieties.indexOf(b.group)
        ),
    [filteredFeatures, focusedVarieties]
  );

  useEffect(() => {
    if (trial) {
      trackEvent(ANALYTICS_EVENTS.ANALYTICS_IS_BEING_RENDERED);
      onTrialChange();
    }
  }, [trial]);

  const timelineComponent = trial?.trial_dates && (
    <Timeline
      dates={aucModeChecked ? filteredDates : trial.trial_dates}
      selectedDate={selectedDate}
      aucModeChecked={aucModeChecked}
      aucDatesIndexes={aucDatesIndexes}
      setAucDatesIndexes={setAucDatesIndexes}
      onDateChange={refetch}
      disabled={refreshing}
    />
  );
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
    trackEvent(ANALYTICS_EVENTS.ANALYTICS_AF_BUTTON);
  };

  const filteringProfile = useSelector(
    (state) => state.resultMap.filteringProfile
  );

  // Key given to advanced filtering to reset component whenever the date changes
  // and/or the scope is reset
  const advancedFilteringKey = useMemo(
    () =>
      [
        selectedDate,
        filteringProfile.scope,
        filteringProfile.id,
        ...Object.keys(numericalPropertiesMetrics),
      ].join(","),
    [
      selectedDate,
      filteringProfile.scope,
      filteringProfile.id,
      numericalPropertiesMetrics,
    ]
  );

  /*
   * Layout
   * Jsx layout based on reactstrap row/col
   * Col must have colClasses, rows must have prop noGutters={true}
   * displays and layouts components of class "powerdash-component"
   */
  return (
    <>
      <Joyride
        continuous
        showProgress
        hideCloseButton
        hideBackButton
        spotlightClicks
        callback={({ type }) => {
          if (type === EVENTS.TOUR_START) setIsOffcanvasOpen(true);
          // User completed or dismissed tour
          if (type === EVENTS.TOUR_END)
            requestSetCompletedTour(user).then(() => dispatch(refreshUser()));
        }}
        styles={{
          tooltip: {
            padding: "8px",
          },
          options: {
            primaryColor: HIPHEN_GREEN,
          },
        }}
        steps={ADVANCED_FILTERING_STEPS}
        run={Boolean(trial) && !user.self.completed_guided_tour}
      />
      {focusedVarieties.length > 0 && userCanRatePlots && (
        <PlotRatingModal
          key={focusedPlots}
          isOpen={plotRatingModalIsOpen}
          toggle={() => {
            setPlotRatingModalOpen((currentState) => !currentState);
            refetch(selectedDate);
          }}
          timelineComponent={timelineComponent}
          focusedPlots={focusedPlots}
          focusedElements={focusedVarieties}
          getColor={getColor}
          trial={trial}
          user={user}
          date={selectedDate}
          plotRatingConfig={plotRatingConfig}
          favoriteElements={favoriteVarieties}
        />
      )}
      {/* First row : filters and content */}
      <Row className="row1 g-0">
        <Col className={AD_COL_CLASSES} sm="2">
          <Row className="h-100 g-0">
            <Col className={AD_COL_CLASSES} sm="12">
              <ControlSection
                trial={trial}
                onSelectTrial={setExternalTrialSelected}
                sortedUserTrials={sortedUserTrials}
                refreshing={refreshing}
                selectedContract={selectedContract}
                contracts={user.contracts}
                toggleOffcanvas={toggleOffcanvas}
              />
              <Offcanvas
                isOpen={isOffcanvasOpen}
                direction="end"
                backdrop={false}
                className="offcanvas-wrap"
                toggle={toggleOffcanvas}
              >
                <AdvancedFiltering
                  key={advancedFilteringKey}
                  data={aggregatedPrefilteredFeatures}
                  numericalPropertiesMetrics={numericalPropertiesMetrics}
                  toggleOffcanvas={toggleOffcanvas}
                  getColor={getColor}
                />
              </Offcanvas>
              <TraitSelector />
              <ExportSection
                trial={trial}
                selectedDate={selectedDate}
                features={filteredFeatures}
              />
            </Col>
          </Row>
        </Col>
        <Col className={AD_COL_CLASSES} sm="10">
          {trial?.trial_dates && (
            <Row className="g-0">
              <Col className={AD_COL_CLASSES} sm="2">
                <div className="powerdash-component compact-data-display">
                  <div className="compact-data-display-item">
                    <span>Plots</span>
                    <span>
                      {filteredFeatures.length}/{features.length}
                    </span>
                  </div>
                  <div className="compact-data-display-item">
                    <span>Varieties</span>
                    <span>
                      {groups.length}/{aggregatedPrefilteredFeatures.length}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className={AD_COL_CLASSES} sm="8">
                {timelineComponent}
              </Col>
              <Col className={AD_COL_CLASSES} sm="2">
                <div
                  className="powerdash-component"
                  data-tooltip-id="tooltip"
                  data-tooltip-html="
                  <h5>Area Under Curve mode</h5>
                  <i>Check this mode to integrate one <b>trait overtime</b></i><br>
                  This calculates the area under the curve between two dates normalized by the number of days.
                  <br><br>
                  Usage:<br>
                  <ul>
                    <li>Prepare your filtered data: Ranking chart must not be empty</li>
                    <li>Activate AUC Mode</li>
                    <li>Select two dates using area sliders on the timeline (indicators on Evolution chart)</li>
                    <li>Click Compute AUC to calculate AUC on visualized trait (Unavailable while evolution chart is loading)</li>
                    <li>Then a new trait is available in Trait list: select to rank on it</li>
                  <ul>
                  "
                >
                  <ComponentTitle
                    icon="fa-area-chart"
                    title="AUC Mode"
                    topRightContent={
                      filteredDates.length ? (
                        <ReactSwitch
                          onChange={onAucToggle}
                          checked={aucModeChecked}
                          disabled={!filteredDates.length}
                          uncheckedIcon={<></>}
                          className="react-switch"
                          onColor={AUC_COLOR}
                        />
                      ) : (
                        <i className="fa fa-lg fa-cog fa-spin discrete-icon" />
                      )
                    }
                  />
                  {aucModeChecked && scopeTrait && featuresByDate.length && (
                    <div className="powerdash-component transparent">
                      {filteredDates.length < 2 ? (
                        <span className="auc-btn-placeholder">
                          Not enough date to compute AUC.
                        </span>
                      ) : (
                        <Button
                          className="auc-btn"
                          disabled={filteredDates.length < 2}
                          onClick={() => {
                            computeAUC(featuresByDate, scopeTrait);
                          }}
                        >
                          Compute integrative{" "}
                          <PropertyName
                            technicalName={scopeTrait.technical_name}
                          />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row className="h-100 g-0">
            <Col className={AD_COL_CLASSES} sm="6">
              <MultipleChart
                displayTabs={filteredFeatures.length > 0}
                selectedTrait={selectedTrait}
                getColor={getColor}
                plotRatingConfig={plotRatingConfig}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="6">
              <HistogramChart
                trait={scopeTrait}
                exportName={`plot_distribution_${trial?.display_name}_${
                  selectedTrait.name
                }${
                  selectedTrait.isAddon ? "" : `_${formatDate(selectedDate)}`
                }`}
              />
              <EvolutionChart
                trait={selectedTrait}
                aucModeChecked={aucModeChecked}
                aucDates={aucDates}
                exportName={`evolution_${trial?.display_name}_${selectedTrait.name}`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="row2 g-0">
        <Col className={`${AD_COL_CLASSES} position-relative`} sm="2">
          <div className="powerdash-component p-0">
            <div
              className={`minimap ${mapExpanded ? "minimap-expanded" : ""}`}
              onMouseEnter={() => {
                mapExpTimeout = setTimeout(() => {
                  //delay the expanding of the map
                  setMapExpanded(true);
                }, 300);
              }}
              onMouseLeave={() => {
                if (mapExpTimeout) mapExpTimeout = clearTimeout(mapExpTimeout);
                setTimeout(() => {
                  //delay the expanding of the map
                  setMapExpanded(false);
                }, 100);
              }}
            >
              <Worldmap
                zoom={2}
                hideNavbar={true}
                hideHud={!mapExpanded}
                contextIsAnalytics={true}
                externalTrialSelected={externalTrialSelected}
                setExternalTrialSelected={setExternalTrialSelected}
              />
            </div>
          </div>
        </Col>

        <Col className={AD_COL_CLASSES} sm="10">
          <Row className={"h-100 g-0"}>
            <Col className={AD_COL_CLASSES} sm="3">
              <ExpandedFocusView
                groups={groups}
                expandFocusView={expandFocusView}
                setExpandFocusView={setExpandFocusView}
                propertyFilter={propertyFilter}
                setPropertyFilter={setPropertyFilter}
                getColor={getColor}
                getImage={getImage}
                highlightedProperty={highlightedProperty}
                setHighlightedProperty={setHighlightedProperty}
                openPlotRatingModal={() => {
                  setPlotRatingModalOpen(true);
                }}
                userCanRatePlots={userCanRatePlots}
                plotRatingConfig={plotRatingConfig}
              />
              <FocusList
                groups={groups}
                expandFocusView={expandFocusView}
                setExpandFocusView={setExpandFocusView}
                propertyFilter={propertyFilter}
                setPropertyFilter={setPropertyFilter}
                getColor={getColor}
                selectedFeature={selectedFeature}
                openPlotRatingModal={() => {
                  setPlotRatingModalOpen(true);
                  trackEvent(ANALYTICS_EVENTS.RATE_PLOTS);
                }}
                userCanRatePlots={userCanRatePlots}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <VarietyThumbnail
                visualizedElement={visualizedVarieties[0]}
                getColor={getColor}
                features={preFilteredFeatures}
                propertyFilter={propertyFilter}
                getImage={getImage}
                highlightedProperty={highlightedProperty}
                setHighlightedProperty={setHighlightedProperty}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <VarietyThumbnail
                visualizedElement={visualizedVarieties[1]}
                getColor={getColor}
                features={preFilteredFeatures}
                propertyFilter={propertyFilter}
                getImage={getImage}
                highlightedProperty={highlightedProperty}
                setHighlightedProperty={setHighlightedProperty}
              />
            </Col>
            <Col className={AD_COL_CLASSES} sm="3">
              <VarietyThumbnail
                visualizedElement={visualizedVarieties[2]}
                getColor={getColor}
                features={preFilteredFeatures}
                propertyFilter={propertyFilter}
                getImage={getImage}
                highlightedProperty={highlightedProperty}
                setHighlightedProperty={setHighlightedProperty}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

BreederReportTemplate.propTypes = {
  externalTrialSelected: PropTypes.object,
  onTrialChange: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  setExternalTrialSelected: PropTypes.func.isRequired,
  sortedUserTrials: PropTypes.array.isRequired,
};
