import "./glossary.css";

import { BBCH_STAGES, FEATURE_PROPERTY_CATEGORIES } from "../constants";
import { Badge, Card, Col, Modal, Row } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertComponent from "../components/alert";
import { ComplexSearch } from "../powerdash/components/complexSearch/ComplexSearch";
import DEFAULT_TRAIT_ICON from "../static/img/default_trait_icon.png";
import { Hr } from "../components/Hr";
import LoadingImg from "../components/loading";
import NavbarComponent from "../components/navbar";
import PropTypes from "prop-types";
import ReactSlider from "react-slider";
import biomassProxy from "../static/img/glossaryCategories/Biomass Proxy.png";
import canopyDev from "../static/img/glossaryCategories/Canopy Development.png";
import { fetchTraitGroups } from "../actions/traits";
import harvestIndex from "../static/img/glossaryCategories/Harvest Index and Quality.png";
import { hasBackofficeAccess } from "../services/roles";
import plantStress from "../static/img/glossaryCategories/Plant Stress.png";
import trialQuality from "../static/img/glossaryCategories/Trial Quality.png";

export const glossaryCategories = Object.freeze({
  "Biomass Proxy": biomassProxy,
  "Canopy Development": canopyDev,
  "Harvest Index and Quality": harvestIndex,
  "Plant Stress": plantStress,
  "Trial Quality": trialQuality,
});

const TraitCard = ({ trait, selected, onClick }) => (
  <Col xl={4} lg={6} xs={12} className="p-2">
    <Card
      className={`trait-card clickable ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="trait-card-header">
        <div>
          <Badge className="trait-card-badge">{trait.type}</Badge>
        </div>
        <i className="fa fa-chevron-right hover-indicator" />
      </div>
      <span className="trait-card-name">{trait.name}</span>
      <span className="trait-card-unit">{trait.unit}</span>
    </Card>
  </Col>
);

export const Glossary = () => {
  const traitGroupsFetched = useSelector(
    (state) => state.traits.traitGroupsFetched
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const traitGroupsData = useSelector((state) => state.traits.groups);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBBCH, setSelectedBBCH] = useState(-1);
  const [selectedTraitId, setSelectedTraitId] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [showDeprecated, setShowDeprecated] = useState(false);
  const [zoomedIllustration, setZoomedIllustration] = useState(null);
  const [bbchLabel, setBbchLabel] = useState("");

  /* Traits groups displayed in the glossary
   * based on traitGroupData and the selected filters
   */
  const traitGroups = useMemo(
    () =>
      traitGroupsData.filter(
        ({
          deprecated,
          category,
          MinBBCHStage,
          MaxBBCHStage,
          TraitGroupDataTypes,
        }) =>
          (selectedDataType
            ? TraitGroupDataTypes.map(({ dataType }) => dataType).includes(
                selectedDataType
              )
            : true) &&
          ((showDeprecated && hasBackofficeAccess(user.self)) || !deprecated) &&
          (selectedCategory ? selectedCategory === category : true) &&
          (selectedBBCH !== -1
            ? selectedBBCH >= MinBBCHStage.order - 1 &&
              selectedBBCH <= MaxBBCHStage.order - 1
            : true)
      ),
    [
      traitGroupsData,
      selectedCategory,
      selectedBBCH,
      showDeprecated,
      selectedDataType,
      user,
    ]
  );

  const traitGroupDataTypes = useMemo(
    () =>
      Array.from(
        new Set(
          traitGroupsData.flatMap((traitGroup) =>
            traitGroup.TraitGroupDataTypes.map(({ dataType }) => dataType)
          )
        )
      ),
    [traitGroupsData]
  );

  // Create a list of traits based on trait groups "Traits"
  const traits = useMemo(
    () =>
      traitGroups.flatMap((traitGroup) =>
        traitGroup.Traits.map((trait) => ({
          ...trait,
          traitGroup: traitGroup,
        }))
      ),
    [traitGroups]
  );

  const searchContent = useMemo(
    () => ({
      categories: {
        options: Object.entries(glossaryCategories)
          .filter(([category]) =>
            selectedCategory ? selectedCategory === category : true
          )
          .map(([category, image]) => ({
            renderBullet: <img alt={category} src={image} />,
            label: category,
            value: category,
          })),
        handleClick: setSelectedCategory,
      },
      groups: {
        options: traitGroups.map(({ name, icon }) => ({
          renderBullet: <img alt={name} src={icon ?? DEFAULT_TRAIT_ICON} />,
          label: name,
          value: name,
        })),
        handleClick: (value) => {
          window.location.href = `#${value}`;
        },
      },
      traits: {
        options: traits.map(({ uuid, name }) => ({
          renderBullet: FEATURE_PROPERTY_CATEGORIES.HIPHEN.icon,
          label: name,
          value: uuid,
        })),

        handleClick: (value) => {
          setSelectedTraitId(value);
        },
      },
    }),
    [traitGroups, traits, selectedCategory]
  );

  const selectedTrait = useMemo(
    () => traits.find(({ uuid }) => uuid === selectedTraitId),
    [traits, selectedTraitId]
  );

  useEffect(() => {
    if (!traitGroupsFetched) dispatch(fetchTraitGroups());
  }, [traitGroupsFetched, dispatch]);

  useEffect(() => {
    if (showDeprecated) window.location.href = "#Legacy";
  }, [showDeprecated]);

  return (
    <div className="wrapper">
      <NavbarComponent />
      <AlertComponent />

      <Modal
        isOpen={Boolean(zoomedIllustration)}
        toggle={() => setZoomedIllustration(null)}
        className="illustration-modal"
        centered
      >
        <img src={zoomedIllustration} alt="Trait illustration" />
      </Modal>

      {traitGroupsFetched ? (
        <div className="page-content container-fluid">
          <Row className="glossary-wrapper g-0">
            <Col xs={2} className="glossary-header">
              <ComplexSearch
                placeholder="Search glossary ..."
                content={searchContent}
              />
              <hr />
              <div className="glossary-header-item">
                <span>
                  <i className="fa fa-star discrete-icon" /> Categories
                </span>
                <div className="glossary-header-categories">
                  {Object.entries(glossaryCategories).map(
                    ([category, icon]) => (
                      <div
                        key={category}
                        className={`glossary-header-categories-item clickable ${
                          selectedCategory !== null ? "active" : ""
                        } ${selectedCategory === category ? "selected" : ""}`}
                        onClick={() => {
                          setSelectedCategory((current) =>
                            current === category ? null : category
                          );
                        }}
                      >
                        <img src={icon} alt={category} />
                        <span>{category}</span>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="glossary-header-item">
                <span>
                  <i className="fa fa-camera discrete-icon" /> Data types
                </span>
                <div className="glossary-header-data-types clickable">
                  {traitGroupDataTypes.map((dataType) => (
                    <Badge
                      key={dataType}
                      className={`trait-group-data-type clickable ${
                        selectedDataType !== null ? "active" : ""
                      } ${selectedDataType === dataType ? "selected" : ""}`}
                      onClick={() => {
                        setSelectedDataType((current) =>
                          current === dataType ? null : dataType
                        );
                      }}
                    >
                      {dataType}
                    </Badge>
                  ))}
                </div>
              </div>
              <div className="glossary-header-item">
                <span>
                  <i className="fa fa-leaf discrete-icon" /> BBCH Stage
                </span>
                <div>
                  <ReactSlider
                    defaultValue={-1}
                    value={selectedBBCH}
                    step={1}
                    min={-1}
                    max={10}
                    onChange={(val) => setBbchLabel(BBCH_STAGES[val])}
                    onAfterChange={setSelectedBBCH}
                    className="bbch-slider-track"
                    thumbClassName="bbch-slider-thumb"
                    withTracks={false}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <span>
                          {state.valueNow < 0
                            ? "All"
                            : state.valueNow > 9
                            ? "H"
                            : state.valueNow}
                        </span>
                      </div>
                    )}
                  />
                  <div className="bbch-stage-label" title={bbchLabel}>
                    {bbchLabel}
                  </div>
                </div>
              </div>
              {hasBackofficeAccess(user.self) && (
                <div className="glossary-header-item">
                  <span
                    className="clickable"
                    onClick={() =>
                      setShowDeprecated((currentValue) => !currentValue)
                    }
                  >
                    <i
                      className={`fa ${
                        showDeprecated ? "fa-check-square" : "fa-square-o"
                      }`}
                    />{" "}
                    Show deprecated traits
                  </span>
                </div>
              )}
            </Col>
            <Col className="glossary-content">
              {traitGroups.map((traitGroup) => (
                <div
                  className="trait-group"
                  key={traitGroup.name}
                  id={traitGroup.name}
                >
                  <h4 className="trait-group-name">
                    <img
                      src={traitGroup.icon ?? DEFAULT_TRAIT_ICON}
                      alt={traitGroup.name}
                    />
                    {traitGroup.name}{" "}
                    <a
                      className="trait-group-name-anchor"
                      href={`#${traitGroup.name}`}
                    >
                      <i
                        className="fa fa-link"
                        title={`Direct link to ${traitGroup.name}`}
                      />
                    </a>
                    {traitGroup.TraitGroupDataTypes.map(({ dataType }) => (
                      <Badge className="trait-group-data-type" key={dataType}>
                        {dataType}
                      </Badge>
                    ))}
                  </h4>
                  <Row className="trait-group-traits g-0">
                    {traitGroup.Traits.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    ).map((trait) => (
                      <TraitCard
                        key={trait.uuid}
                        trait={trait}
                        selected={selectedTraitId === trait.uuid}
                        onClick={() => {
                          setSelectedTraitId(trait.uuid);
                        }}
                      />
                    ))}
                  </Row>
                  <br />
                </div>
              ))}
            </Col>
            <Col xs={4}>
              {selectedTrait ? (
                <Card
                  key={`big-card ${selectedTrait.name}`}
                  className="powerdash-component glossary-trait-big-card"
                >
                  <div className="glossary-trait-big-card-header">
                    <img
                      src={selectedTrait.traitGroup.icon ?? DEFAULT_TRAIT_ICON}
                      alt={selectedTrait.traitGroup.name}
                    />
                    <div className="glossary-trait-big-card-header-text">
                      <span className="glossary-trait-big-card-header-trait-group">
                        {selectedTrait.traitGroup.name}
                      </span>
                      <span className="glossary-trait-big-card-header-trait">
                        {selectedTrait.name}
                      </span>
                    </div>
                  </div>
                  <div className="glossary-trait-big-card-boxes">
                    <div>
                      <span>TYPE</span>
                      <span>{selectedTrait.type}</span>
                    </div>
                    <div>
                      <span>UNIT</span>
                      <span>{selectedTrait.unit}</span>
                    </div>
                    <div>
                      <span>BBCH</span>
                      <span>
                        {`${selectedTrait.MinBBCHStage.stage} - ${selectedTrait.MaxBBCHStage.stage}`}
                      </span>
                    </div>
                  </div>
                  <Hr />
                  <div className="glossary-trait-big-card-item">
                    <h5>Data types</h5>
                    <div>
                      {selectedTrait.traitGroup.TraitGroupDataTypes.map(
                        ({ dataType }) => (
                          <Badge
                            key={dataType}
                            className="trait-group-data-type"
                          >
                            {dataType}
                          </Badge>
                        )
                      )}
                    </div>
                  </div>
                  <Hr />
                  <div className="glossary-trait-big-card-item">
                    <h5>Category</h5>
                    <div className="glossary-trait-big-card-category">
                      <img
                        src={
                          glossaryCategories[selectedTrait.traitGroup.category]
                        }
                        alt={selectedTrait.traitGroup.category}
                      />
                      {selectedTrait.traitGroup.category}
                    </div>
                  </div>
                  <Hr />
                  <div className="glossary-trait-big-card-description">
                    <p>{selectedTrait.description}</p>
                    {selectedTrait.illustration && (
                      <img
                        className="glossary-trait-big-card-illustration clickable"
                        src={selectedTrait.illustration}
                        alt={`${selectedTrait.name} illustration`}
                        onClick={() =>
                          setZoomedIllustration(selectedTrait.illustration)
                        }
                      />
                    )}
                  </div>
                </Card>
              ) : (
                <div className="component-placeholder">
                  <img src={DEFAULT_TRAIT_ICON} alt="Default trait icon" />
                  <span>Select a trait to display details</span>
                </div>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <LoadingImg visible />
      )}
    </div>
  );
};

TraitCard.propTypes = {
  trait: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};
