import "./exportSection.css";

import {
  ANALYTICS_EVENTS,
  FEATURE_PROPERTY_CATEGORIES,
} from "../../../../constants";
import { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import { DEFAULT_BLACKLISTED_TRAITS } from "../../../../constants";
import { EXPORT_FORMATS } from "../../../constants";
import { FloatingCard } from "../../../../components/floatingCard/FloatingCard";
import { PropTypes } from "prop-types";
import ReactSelect from "react-select";
import { UploadYourOwnData } from "../uploadYourOwnData/UploadYourOwnData";
import download from "downloadjs";
import { useSelector } from "react-redux";
import { useTracking } from "../../../../analytics";

export const ExportSection = ({ trial, selectedDate, features }) => {
  const formatOptions = Object.keys(EXPORT_FORMATS);
  const [selectedFormat, setSelectedFormat] = useState(formatOptions[0]);
  const [downloadDisabled, setDownloadDisabled] = useState(false);

  const format = EXPORT_FORMATS[selectedFormat];
  const fileName = `${trial?.display_name}_${selectedDate}${format.ext}`;
  const traits = useSelector(({ resultMap }) => resultMap.traitsListForMap);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);

  useEffect(() => {
    setDownloadDisabled(false);
  }, [features, selectedFormat]);
  const { trackEvent } = useTracking();

  const exportData = () => {
    const traitNames = traits.reduce(
      (currentTraits, trait) => ({
        ...currentTraits,
        [trait.technical_name]: trait.name,
      }),
      {}
    );
    const traitValues = Object.values(traitNames);

    // Features are re-mapped for export changing the keys of technical trait names to their display name
    const remappedFeatures = features.map((feature) => {
      const { properties, displayId, id, experiment } = feature;
      return {
        cloverfieldId: id,
        id: displayId,
        experiment,
        ...Object.entries(properties)
          .filter(
            ([key]) => !DEFAULT_BLACKLISTED_TRAITS.includes(key.toLowerCase())
          )
          .reduce(
            (currentProps, [property, value]) => ({
              ...currentProps,
              [traitNames[property] ?? property]: value,
            }),
            {}
          ),
      };
    });

    setDownloadDisabled(true);
    let dataString = "";
    switch (selectedFormat) {
      case "JSON":
        dataString += JSON.stringify(remappedFeatures).trim();
        break;
      case "CSV":
        var replacer = (key, value) => {
          return value == null || value === "∅" ? "" : value;
        };
        const columns = new Set(
          remappedFeatures.flatMap((row) => Object.keys(row))
        );
        const header = Array.from(columns).sort((a, b) => {
          if (a === "cloverfieldId") return -1;
          if (b === "cloverfieldId") return 1;
          if (a === "id") return -1;
          if (b === "id") return 1;
          // Sort by is (not) trait then by alphabetical order
          const traitCompare =
            +traitValues.includes(a) - +traitValues.includes(b);
          return traitCompare === 0 // means that both or neither is a trait
            ? a.toLowerCase().localeCompare(b.toLowerCase())
            : traitCompare;
        });
        const csv = [
          header.join(";"), // header row first
          ...remappedFeatures.map((row) =>
            header
              .map((fieldName) =>
                JSON.stringify(row[fieldName], replacer).trim()
              )
              .join(";")
          ),
        ].join("\n");
        dataString += csv;
        break;
      default:
        break;
    }
    const indexOfDot = fileName.lastIndexOf(".");
    const fileExtension = fileName.slice(indexOfDot + 1);
    download(dataString, fileName, format.mime);

    trackEvent(ANALYTICS_EVENTS.ANALYTICS_DOWNLOAD_BUTTON, {
      downloadFormat: fileExtension,
    });
  };

  const handleOpenUpload = () => {
    setUploadIsOpen(!uploadIsOpen);
    trackEvent(ANALYTICS_EVENTS.OPEN_UPLOAD_YOUR_OWN_DATA);
  };

  return (
    <div className="powerdash-component export-section">
      {features.length > 0 ? (
        <>
          <div className="export-section-content">
            <ReactSelect
              className="export-format-select"
              placeholder="Format"
              menuPlacement="top"
              value={{ label: selectedFormat, value: selectedFormat }}
              options={formatOptions.map((opt) => {
                return { label: opt, value: opt };
              })}
              onChange={({ value }) => setSelectedFormat(value)}
            ></ReactSelect>
            <Button
              className="download-btn"
              disabled={downloadDisabled}
              onClick={exportData}
              data-tooltip-id="tooltip"
              data-tooltip-content={`Download filtered and calculated data to ${selectedFormat}`}
              data-tooltip-place="top"
            >
              <i className="fa fa-download"></i>
            </Button>
          </div>

          <Button
            className="hiphen-green-button"
            style={{
              backgroundColor: FEATURE_PROPERTY_CATEGORIES.IMPORTED.color,
            }}
            onClick={handleOpenUpload}
            size="sm"
          >
            <i className="fa fa-upload me-1" aria-hidden="true" />
            Upload my custom data
          </Button>
          {uploadIsOpen && (
            <div className="position-relative">
              <FloatingCard hide={() => setUploadIsOpen(false)}>
                <UploadYourOwnData
                  trial={trial}
                  setUploadIsOpen={setUploadIsOpen}
                />
              </FloatingCard>
            </div>
          )}
        </>
      ) : (
        <ComponentPlaceholder icon="fa-download" text="" />
      )}
    </div>
  );
};

ExportSection.propTypes = {
  features: PropTypes.array.isRequired,
  trial: PropTypes.object,
  selectedDate: PropTypes.string,
};
