import "./filteringProfileManager.css";

import {
  ANALYTICS_EVENTS,
  MAX_ATTRIBUTES_SELECT_VALUES,
  THEME,
} from "../../../../../constants";
import {
  Alert,
  Badge,
  Button,
  Collapse,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createFilteringProfile,
  deleteFilteringProfile,
  resetFilteringProfile,
  setExperimentsFilter,
  setFilteringProfile,
  setLayerFilter,
  setModalitiesFilter,
  updateFilteringProfile,
} from "../../../../../actions/resultMap";
import {
  requestCreateFilteringProfile,
  requestDeleteFilteringProfile,
  requestFetchFilteringProfile,
} from "../../../../../services/backendRequests";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";

import { Attributes } from "./attributes/Attributes";
import { EditableLabel } from "../../../../../components/editableLabel/EditableLabel";
import { LabelledSeparator } from "../../../../../components/labelledSeparator/LabelledSeparator";
import { NULL_GROUP_LABEL } from "../../../../constants";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../../components/PropertyName/PropertyName";
import ReactSelect from "react-select";
import { ScopeItemSelector } from "./ScopeItemSelector/ScopeItemSelector";
import { createModalityBadgeStyle } from "../../../../utils";
import moment from "moment";
import { useTracking } from "../../../../../analytics";

const customStyles = {
  control: (provided, _) => ({
    ...provided,
    maxHeight: "90px",
  }),
  valueContainer: (provided, _) => ({
    ...provided,
    maxHeight: "80px",
    overflowY: "auto",
  }),
  indicatorsContainer: (provided, _) => ({
    ...provided,
    maxHeight: "80px",
  }),
};

const CustomFilteringProfileOption = ({
  innerProps,
  isDisabled,
  label,
  value,
}) =>
  !isDisabled && (
    <div
      className="custom-filtering-profile-option clickable d-flex align-items-center justify-content-between"
      {...innerProps}
    >
      <div>
        <small>{moment(value.created_at).format("YYYY-MM-DD HH:mm")}</small>
        <br />
        <span>{label}</span>
      </div>
      <i
        className={`fa fa-star ${
          value.favourite ? "favourite" : "not-favourite"
        }`}
        aria-hidden="true"
      />
    </div>
  );

export const FilteringProfileManager = ({
  filteringProfile,
  toggleOffcanvas,
  invalidScope,
}) => {
  const [
    experiments,
    modalities,
    attributes,
    trial,
    filteringProfiles,
    uploadedData,
  ] = useSelector(({ resultMap }) => [
    resultMap.distinctExperiments,
    resultMap.distinctModalities,
    resultMap.attributes,
    resultMap.trial,
    resultMap.filteringProfiles,
    resultMap.uploadedData,
  ]);
  const [name, setName] = useState(
    `Filtering Profile ${filteringProfiles.length + 1}`
  );
  const [alert, setAlert] = useState(null);
  const [scopeAttributesOpen, setScopeAttributesOpen] = useState(false);
  const toggle = () => setScopeAttributesOpen(!scopeAttributesOpen);
  const [expandedAttributes, setExpandedAttributes] = useState({});

  const user = useSelector((state) => state.user);

  const filteringProfilesOptions = useMemo(() => {
    const sortedProfiles = filteringProfiles.toSorted((a, b) => {
      // First, sort by favourite status
      if (b.favourite !== a.favourite) return b.favourite - a.favourite;

      // Then, sort alphabetically by name
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return sortedProfiles.map((fp) => ({
      label: fp.name,
      value: fp,
      favourite: fp.favourite,
    }));
  }, [filteringProfiles]);

  const handleFavoriteToggle = () => {
    const newFavoriteStatus = !filteringProfile.favourite;
    const updatedProfile = {
      ...filteringProfile,
      favourite: newFavoriteStatus,
    };

    dispatch(updateFilteringProfile(updatedProfile));
  };

  const layers = useSelector((state) => state.resultMap.distinctLayers);
  const {
    modalities: selectedModalities,
    experiments: selectedExperiments,
    layer: selectedLayer,
    attributes: selectedAttributes,
  } = useSelector((state) => state.resultMap.filters);

  const dispatch = useDispatch();

  const setSelectedExperiments = (experiments) => {
    dispatch(setExperimentsFilter(experiments));
  };

  const setSelectedModalities = (modalities) => {
    dispatch(setModalitiesFilter(modalities));
  };

  const setSelectedLayer = (layer) => {
    dispatch(setLayerFilter(layer));
  };

  const handleSelectAllExperiments = () => {
    if (selectedExperiments.length === experimentsOptions.length) {
      // If all items are already selected, deselect them
      setSelectedExperiments([]);
    } else {
      // Otherwise, select all items
      const allExperiments = experimentsOptions.map(
        (experiment) => experiment.value
      );
      setSelectedExperiments(allExperiments);
    }
  };
  const handleToggleExpand = (key) => {
    setExpandedAttributes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  const handleSelectAllModalities = () => {
    if (selectedModalities.length === modalityOptions.length) {
      // If all items are already selected, deselect them
      setSelectedModalities([]);
    } else {
      // Otherwise, select all items
      const allModalities = modalityOptions.map((modality) => modality.value);
      setSelectedModalities(allModalities);
    }
  };

  const modalityOptions = modalities.map((modality) => {
    return { label: modality ?? NULL_GROUP_LABEL, value: modality };
  });

  const experimentsOptions = experiments.map((experiment) => {
    return { label: experiment ?? NULL_GROUP_LABEL, value: experiment };
  });

  const layersOptions = layers.map((layer) => {
    return { label: layer, value: layer };
  });

  const handleLoadFilteringProfile = async (id) => {
    const filteringProfile = await requestFetchFilteringProfile(user, id);
    dispatch(setFilteringProfile(filteringProfile));
  };
  const { trackEvent } = useTracking();

  const handleCreateFilteringProfile = async () => {
    const newFilteringProfile = {
      name: name,
      scope: {
        experiments: selectedExperiments,
        modalities: selectedModalities,
        layer: selectedLayer,
        attributes: selectedAttributes,
        uses_imported_data: uploadedData !== null,
      },
      filters: [],
      blacklist: [],
      whitelist: [],
    };
    try {
      const res = await requestCreateFilteringProfile(
        user,
        trial,
        newFilteringProfile
      );
      dispatch(createFilteringProfile(res));
      setAlert(null);
    } catch (err) {
      setAlert(err);
    }

    trackEvent(ANALYTICS_EVENTS.AF_SAVE_SCOPE);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toggleModal = () => setIsDeleteModalOpen((prev) => !prev);

  return (
    <div
      className={`filtering-profile-manager p-2 pb-0 ${
        !filteringProfile.scope ? "focused" : ""
      }`}
    >
      <Modal isOpen={isDeleteModalOpen} toggle={toggleModal}>
        <ModalHeader>Delete current filtering profile ?</ModalHeader>
        <ModalBody toggle={toggleModal}>
          This operation is irreversible
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              requestDeleteFilteringProfile(user, filteringProfile);
              dispatch(deleteFilteringProfile(filteringProfile));
              toggleModal();
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex gap-2 align-items-center justify-content-between mb-1 pe-2">
        <div className="filtering-profile-manager-header d-flex gap-2 align-items-center">
          <Button
            className="button-new"
            onClick={() => {
              // First it resets the filtering profile
              dispatch(resetFilteringProfile());
              setName(`Filtering Profile ${filteringProfiles.length + 1}`);
            }}
          >
            <i className="fa fa-plus" />
          </Button>
          <ReactSelect
            className="flex-grow-1"
            placeholder="Load filtering profile"
            value={filteringProfilesOptions.find(
              (e) => e.value.id === filteringProfile.id
            )}
            options={filteringProfilesOptions}
            onChange={({ value }) => handleLoadFilteringProfile(value.id)}
            components={{ Option: CustomFilteringProfileOption }}
          />

          {Boolean(filteringProfile.id) && (
            <>
              <i
                onClick={handleFavoriteToggle}
                className={`fa fa-star ${
                  filteringProfile.favourite ? "favourite" : "not-favourite"
                } clickable`}
                aria-hidden="true"
              />
              <i
                className="fa fa-lg fa-trash discrete-icon clickable"
                onClick={toggleModal}
              />
            </>
          )}
        </div>
        <i
          className="fa fa-lg fa-times discrete-icon 
 clickable"
          onClick={toggleOffcanvas}
        />
      </div>
      {!Boolean(filteringProfile.id) && (
        <EditableLabel value={name} onChange={setName} />
      )}
      <div id="advanced-filtering-scope-selector" className="p-2 pt-1">
        <Label>
          Scope{" "}
          {filteringProfile.scope ? (
            <i className="fa fa-lock" />
          ) : (
            <span className="tip">
              please define a set of pre-filtered plots before applying more
              filters
            </span>
          )}
        </Label>
        {!filteringProfile.scope ? (
          <>
            <div className="scope-selection pt-1 p-2">
              <ScopeItemSelector
                labelContent={
                  <>
                    <i className="fa fa-th-large discrete-icon" /> experiment
                  </>
                }
                selected={selectedExperiments}
                options={experimentsOptions}
                handleSelectAll={handleSelectAllExperiments}
              />
              <ReactSelect
                className="attribute"
                value={selectedExperiments.map((experiment) => ({
                  label: experiment ?? NULL_GROUP_LABEL,
                  value: experiment,
                }))}
                onChange={(elements) =>
                  setSelectedExperiments(
                    elements ? elements.map((element) => element.value) : []
                  )
                }
                options={experimentsOptions}
                closeMenuOnSelect={false}
                maxHeight={3}
                displayControls
                styles={customStyles}
                isMulti
              />

              <ScopeItemSelector
                labelContent={
                  <>
                    <i className="fa fa-eyedropper discrete-icon" /> modality
                  </>
                }
                selected={selectedModalities}
                options={modalityOptions}
                handleSelectAll={handleSelectAllModalities}
              />
              <ReactSelect
                className="attribute"
                value={selectedModalities.map((modality) => ({
                  label: modality ?? NULL_GROUP_LABEL,
                  value: modality,
                }))}
                onChange={(elements) =>
                  setSelectedModalities(
                    elements ? elements.map((element) => element.value) : []
                  )
                }
                styles={{
                  ...customStyles,
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 3,
                      backgroundColor: data.value
                        ? `${THEME.modalityColorHash.hex(data.value)}99`
                        : "#E6E6E6",
                    };
                  },
                }}
                options={modalityOptions}
                closeMenuOnSelect={false}
                maxHeight={3}
                displayControls
                isMulti
              />

              {layersOptions.length > 0 && (
                <>
                  <label className="my-1">Layer</label>
                  <ReactSelect
                    className="attribute"
                    value={{ label: selectedLayer, value: selectedLayer }}
                    isDisabled={layersOptions.length === 0}
                    onChange={({ value }) => setSelectedLayer(value)}
                    options={layersOptions}
                    maxHeight={3}
                    displayControls
                  />
                </>
              )}

              {Object.keys(attributes).length > 0 && (
                <Attributes
                  selectedAttributes={selectedAttributes}
                  attributes={attributes}
                />
              )}
            </div>
            <div className="pt-2 d-flex justify-content-between align-items-center">
              <small className="text-danger">{alert}</small>
              <Button
                disabled={invalidScope}
                className="hiphen-green-button"
                size="sm"
                onClick={handleCreateFilteringProfile}
              >
                <i className="fa fa-lock" /> Save scope & Start filtering
              </Button>
            </div>
          </>
        ) : (
          <div className="scope-summary p-2 d-flex gap-2 flex-column">
            <div className="attribute">
              <i className="fa fa-th-large" /> experiment{" "}
              <Badge className="count-badge">
                {filteringProfile.scope.experiments.length}
              </Badge>
              <div className="stack">
                {filteringProfile.scope.experiments.map((experiment) => (
                  <Badge
                    key={experiment ?? NULL_GROUP_LABEL}
                    className="hiphen-badge small ms-1 mt-1"
                  >
                    {experiment ?? NULL_GROUP_LABEL}
                  </Badge>
                ))}
              </div>
            </div>
            <div className="attribute">
              <i className="fa fa-eyedropper" /> modality{" "}
              <Badge className="count-badge">
                {filteringProfile.scope.modalities.length}
              </Badge>
              <div className="stack">
                {filteringProfile.scope.modalities.map((modality) => (
                  <span
                    key={modality ?? NULL_GROUP_LABEL}
                    className="ms-1 mt-1"
                    style={createModalityBadgeStyle(modality)}
                  >
                    {modality ?? NULL_GROUP_LABEL}
                  </span>
                ))}
              </div>
            </div>
            {filteringProfile.scope.layer && (
              <div>
                <i className="fa fa-bars" /> {filteringProfile.scope.layer}
              </div>
            )}
            {Object.keys(filteringProfile.scope.attributes).length > 0 && (
              <>
                <LabelledSeparator className="gap-2 clickable" onClick={toggle}>
                  <span>Other Attributes</span>{" "}
                  <i
                    className={`fa fa-chevron-down discrete-icon ${
                      scopeAttributesOpen ? "fa-rotate-180" : ""
                    }`}
                  />
                </LabelledSeparator>

                <Collapse isOpen={scopeAttributesOpen}>
                  <>
                    {Object.entries(filteringProfile.scope.attributes)
                      .sort(([keyA, valuesA], [keyB, valuesB]) => {
                        // Prioritize length < 50, then sort alphabetically by label
                        if (
                          valuesA.length < MAX_ATTRIBUTES_SELECT_VALUES &&
                          valuesB.length >= MAX_ATTRIBUTES_SELECT_VALUES
                        )
                          return -1;
                        if (
                          valuesA.length >= MAX_ATTRIBUTES_SELECT_VALUES &&
                          valuesB.length < MAX_ATTRIBUTES_SELECT_VALUES
                        )
                          return 1;
                        return keyA.localeCompare(keyB);
                      })
                      .map(([key, values]) => {
                        const exceededSize =
                          values.length > MAX_ATTRIBUTES_SELECT_VALUES;
                        const displayedValues = expandedAttributes[key]
                          ? values
                          : values.slice(0, MAX_ATTRIBUTES_SELECT_VALUES);
                        const remainingCount =
                          values.length - displayedValues.length;

                        return (
                          <div key={key} className="pt-2 attribute">
                            <PropertyName showIcon technicalName={key} />{" "}
                            <Badge className="count-badge">
                              {values.length}
                            </Badge>
                            <div className="stack">
                              {displayedValues.map((value) => (
                                <Badge
                                  key={value}
                                  className="hiphen-badge small ms-1 mt-1"
                                >
                                  {value ?? NULL_GROUP_LABEL}
                                </Badge>
                              ))}
                              {exceededSize && !expandedAttributes[key] && (
                                <Badge
                                  className="hiphen-badge small ms-1 mt-1 clickable"
                                  onClick={() => handleToggleExpand(key)}
                                >
                                  +{remainingCount}
                                </Badge>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </>
                </Collapse>
              </>
            )}

            {filteringProfile.scope.uses_imported_data && (
              <Alert color="warning" className="p-2 mb-0">
                <i className="fa fa-warning" /> This filtering profile is
                defined using dynamically uploaded data. You might encounter
                inconsistencies if the current data differ from the one used in
                this scope.
              </Alert>
            )}
            {invalidScope && (
              <Alert color="danger" className="p-2 mb-0">
                <i className="fa fa-minus-circle" /> This filtering profile is
                invalid because current selected attributes might be missing in
                data, therefore there is no data to apply filters on.
              </Alert>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FilteringProfileManager.propTypes = {
  toggleOffcanvas: PropTypes.func.isRequired,
  filteringProfile: PropTypes.object.isRequired,
  invalidScope: PropTypes.bool.isRequired,
};
