import "./PropertyBadge.css";

import { Badge } from "reactstrap";
import { MODALITY_PROPERTY } from "../../constants";
import { NULL_GROUP_LABEL } from "../../powerdash/constants";
import PropTypes from "prop-types";
import { PropertyName } from "../PropertyName/PropertyName";
import { createModalityBadgeStyle } from "../../powerdash/utils";
import { truncateNumber } from "../../services/utils";

export const PropertyBadge = ({
  name,
  category,
  value,
  isHighlighted,
  setHighlighted,
  propertyFilter,
}) => {
  return (
    <Badge
      className={`property-badge ${
        category.className ? category.className : ""
      }`}
      color="invalid background color"
      style={{
        backgroundColor: category.light,
        outline: isHighlighted ? `3px solid ${category.color}` : "none",
      }}
      onClick={setHighlighted}
    >
      <PropertyName showIcon technicalName={name} highlight={propertyFilter} />
      <span
        className="value"
        style={
          name === MODALITY_PROPERTY ? createModalityBadgeStyle(value) : null
        }
      >
        {value == null ? NULL_GROUP_LABEL : truncateNumber(value)}
      </span>
    </Badge>
  );
};

PropertyBadge.propTypes = {
  name: PropTypes.string.isRequired,
  propertyFilter: PropTypes.string.isRequired,
  category: PropTypes.shape({
    color: PropTypes.string.isRequired,
    light: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    priority: PropTypes.number.isRequired,
    attribute: PropTypes.bool,
    className: PropTypes.string,
  }),
  isHighlighted: PropTypes.bool,
  setHighlighted: PropTypes.func,
  value: PropTypes.any,
};
