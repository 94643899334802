import "./blackListWhiteList.css";

import {
  addGroupToFilteringProfileBlacklist,
  addGroupToFilteringProfileWhitelist,
  removeGroupFromFilteringProfileBlacklist,
  removeGroupFromFilteringProfileWhitelist,
} from "../../../../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

export const BlackListWhiteList = ({ group }) => {
  const filteringProfile = useSelector(
    (state) => state.resultMap.filteringProfile
  );
  const dispatch = useDispatch();

  const isBlacklisted = filteringProfile.blacklist.includes(group);
  const isWhitelisted = filteringProfile.whitelist.includes(group);

  const handleBlacklistClick = (e) => {
    e.stopPropagation();
    if (isBlacklisted)
      dispatch(removeGroupFromFilteringProfileBlacklist(group));
    else dispatch(addGroupToFilteringProfileBlacklist(group));
  };

  const handleWhitelistClick = (e) => {
    e.stopPropagation();
    if (isWhitelisted)
      dispatch(removeGroupFromFilteringProfileWhitelist(group));
    else dispatch(addGroupToFilteringProfileWhitelist(group));
  };

  return (
    filteringProfile.scope && (
      <div className="bl-wl d-flex gap-2">
        <i
          className={`fa clickable discrete-icon fa-minus-circle blacklist ${
            isBlacklisted ? "active" : ""
          }`}
          onClick={handleBlacklistClick}
          data-tooltip-id="tooltip"
          data-tooltip-content={`${
            isBlacklisted ? "Remove from" : "Add to"
          } Block list`}
          data-tooltip-place="top"
          data-tooltip-variant="error"
        />
        <i
          className={`fa clickable discrete-icon fa-shield whitelist ${
            isWhitelisted ? "active" : ""
          }`}
          onClick={handleWhitelistClick}
          data-tooltip-id="tooltip"
          data-tooltip-content={`${
            isWhitelisted ? "Remove from" : "Add to"
          } Allow list`}
          data-tooltip-place="top"
        />
      </div>
    )
  );
};

BlackListWhiteList.propTypes = {
  group: PropTypes.string.isRequired,
};
