import "./legals.scss";

import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap";

import AlertComponent from "../components/alert";
import NavbarComponent from "../components/navbar";
import PageTitle from "../components/pageTitle";
import React from "react";

export const Legals = () => (
  <div className="wrapper">
    <NavbarComponent />
    <AlertComponent />

    <div className="page-content container-fluid">
      <PageTitle title="Terms & Conditions of Service and Data Use Agreement" />
      <Row>
        <Col xs="3">
          <Card className="sticky-top legals_nav">
            <CardBody>
              <Nav vertical tag="ol" className="ps-3">
                <NavItem>
                  <NavLink href="#preamble">PREAMBLE</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#purpose">PURPOSE</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#service-order">SERVICE ORDER</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#prices-payment-terms-invoicing"
                  >
                    PRICES, PAYMENT TERMS AND INVOICING
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#provision-of-services"
                  >
                    PROVISION OF SERVICES
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#provision-of-equipment"
                  >
                    PROVISION OF EQUIPMENT
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#duration">DURATION</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#intellectual-property"
                  >
                    INTELLECTUAL PROPERTY
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#warranty">WARRANTY</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#liability">LIABILITY</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#customer-responsabilities"
                  >
                    CUSTOMER RESPONSABILITIES
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#confidentiality">CONFIDENTIALITY</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#personal-data">PERSONAL DATA</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="custom-nav-link"
                    href="#compliance-with-anticorruption-regulations"
                  >
                    COMPLIANCE WITH ANTICORRUPTION REGULATIONS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#force-majeure-unpredictability">
                    FORCE MAJEURE AND UNPREDICTABILITY
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#miscellaneous-provisions">
                    MISCELLANEOUS PROVISIONS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#applicable-law-jurisdiction">
                    APPLICABLE LAW AND JURISDICTION
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
        </Col>
        <Col xs="9" className="section mx-auto legals_section">
          <p>
            HIPHEN (hereinafter referred to as “HIPHEN") is a company
            specializing in plant phenotyping. Its main activity is the analysis
            and understanding of its Customers' data, with the aim of increasing
            their knowledge of the health and development of their crops.
            <br />
            HIPHEN seeks to assist agricultural actors, worldwide, in digitising
            their activities by providing and expanding their data.
          </p>
          <h5 className="text-info mb-4 mt-4" id="preamble">
            1. PREAMBLE
          </h5>
          <p>
            <span className="me-2">1.1</span> These general terms and conditions
            of service (hereinafter referred to as the “General Conditions”)
            shall apply, without restriction or reservation, to the services
            provided by HIPHEN to their Customers (hereinafter referred to as
            “Customers”).
            <br />
            Engaging the services provided by HIPHEN shall imply the full and
            unreserved acceptance of these General Conditions.
          </p>
          <p>
            <span className="me-2">1.2</span> The Customer hereby agrees that it
            shall in no way rely on any declaration or promise made or given by
            HIPHEN, which is not set forth in these General Conditions.
          </p>
          <p>
            <span className="me-2">1.3</span> HIPHEN reserves the right to
            modify these General Conditions at any time and without prior
            notice.
          </p>
          <h5 className="text-info mb-4 mt-4" id="purpose">
            2. PURPOSE
          </h5>
          <p>
            <span className="me-2">2.1</span> These General Conditions shall set
            forth the terms and conditions under which HIPHEN performs the
            services defined in the technical proposal (hereinafter referred to
            as the “Technical Proposal”) sent to the Customer by HIPHEN
            (hereinafter referred to as the “Services”). Under these General
            Conditions, HIPHEN agrees to make every effort to perform the
            Services in accordance with the Technical Proposal.
            <br />
            HIPHEN's activity shall be defined as providing analysis and
            decision-making support services, the final choice of which is
            always the Customer's responsibility. HIPHEN provides no consulting
            services.
          </p>
          <p>
            <span className="me-2">2.2</span> For each Customer, the General
            Conditions shall be supplemented by:
            <ul>
              <li>
                The Technical Proposal which shall set forth the methods by
                which the Services shall be performed by HIPHEN;
              </li>
              <li>
                An Estimate which summarizes the Services to be performed and
                the applicable price therefor (hereinafter referred to as the
                "Estimate").
              </li>
            </ul>
          </p>
          <p>
            <span className="me-2">2.3</span> Once accepted by the Customer, the
            General Conditions, the Technical Proposal and the Estimate, shall
            constitute, a binding contract entered into by the parties.
          </p>
          <h5 className="text-info mb-4 mt-4" id="service-order">
            3. SERVICE ORDER
          </h5>
          <p>
            <span className="me-2">3.1</span> Any Customer interested in the
            Services offered by HIPHEN shall first receive a Technical Proposal.
            This shall set forth, in detail, the Customer's needs, the Services
            that HIPHEN shall perform for the Customer and the proposed
            timetable for the performance of said Services.
          </p>
          <p>
            <span className="me-2">3.2</span> Once the Customer agrees with the
            Technical Proposal, and the parties have discussed the project,
            HIPHEN shall then issue an Estimate. This shall summarize the
            Services to be provided and the applicable price. The price shall be
            broken down by Services to be rendered, if more than one Service is
            to be performed.
          </p>
          <p>
            <span className="me-2">3.3</span> By signing the Estimate, the
            Customer thereby agrees to the proposed Services and the methods by
            with they shall be carried out.
          </p>
          <h5
            className="text-info mb-4 mt-4"
            id="prices-payment-terms-invoicing"
          >
            4. PRICES, PAYMENT TERMS AND INVOICING
          </h5>
          <p>
            <span className="me-2">4.1</span> The price offered by HIPHEN for
            the performance of the Services shall be included in the Technical
            Proposal, and shall be set forth, in detail, in the Estimate.
          </p>
          <p>
            <span className="me-2">4.2</span> The Technical Proposal shall also
            set forth, in detail, the terms of payment and invoicing, including
            payment deadlines, payment terms and any penalties for late
            payments.
          </p>
          <p>
            <span className="me-2">4.3</span> The price does not include any
            locator foreign taxes, levies or duties of any category, including
            value-added, sales use or withholding taxes (hereinafter referred to
            as “Taxes”). Customer is responsible for paying all Taxes, excluding
            only taxes based on HIPHEN’s or its authorized reseller’s net
            income. If HIPHEN or its authorized reseller has the legal
            obligation to pay or collect Taxes for which Customer is
            responsible, the appropriate amount shall be invoiced to and paid by
            Customer unless Customer provides HIPHEN or its authorized reseller
            with a valid tax exemption certificate authorized by the appropriate
            taxing authority.
          </p>
          <h5 className="text-info mb-4 mt-4" id="provision-of-services">
            5. PROVISION OF SERVICES
          </h5>
          <p>
            <span className="me-2">5.1</span> The Services shall be performed
            pursuant to the conditions defined in the Technical Proposal. As
            part of the performance of the Services, HIPHEN shall provide the
            Customer with deliverables (hereinafter referred to as the
            “Deliverables ") which are defined herein as any and all tangible or
            intangible results to be provided by HIPHEN to the Customer. The
            Deliverables may differ depending on the Services offered by HIPHEN.
          </p>
          <p>
            <span className="me-2">5.2</span> The deadlines for completion of
            the Services, included in the Technical Proposal, are listed for
            information purposes only and HIPHEN shall not be liable for any
            violation related thereto. However, in the event of a delay in the
            performance of the Services, HIPHEN shall inform the Customer as
            soon as possible.
          </p>
          <p>
            <span className="me-2">5.3</span> Any request for an additional
            Service must be the subject of a new Technical Proposal, followed by
            an Estimate which must be duly accepted by the Customer.
          </p>
          <h5 className="text-info mb-4 mt-4" id="provision-of-equipment">
            6. PROVISION OF EQUIPMENT
          </h5>
          <p>
            <span className="me-2">6.1</span> As part of the performance of the
            Services, HIPHEN may make available or sell equipment such as, for
            example, functional sensors to the Customer. The conditions under
            which this provision and/or sale will be carried out shall be
            defined in the Technical Proposal.
          </p>
          <p>
            <span className="me-2">6.2</span> In the event that said equipment
            is made available, as described in the previous paragraph, the
            Customer shall be solely responsible for the custody and maintenance
            of said equipment as well as any and all related risks thereto, for
            the duration of their provision by HIPHEN.
            <br />
            The Customer shall maintain an appropriate policy of insurance
            covering the risks associated with such provision and shall ensure
            the rigorous maintenance of the equipment which has been provided.
            <br />
            Any damage to the equipment, noted by HIPHEN at the end of the
            provision period, will be charged to the Customer. Any fees or costs
            related to the repair and/or refurbishment and/or cleaning of the
            equipment shall be charged to the Customer.
          </p>
          <p>
            <span className="me-2">6.3</span> If specific type of equipment is
            furnished (for example in the case of the use of a “PhenoMobile”), a
            specific contract shall be concluded between the parties.
          </p>
          <h5 className="text-info mb-4 mt-4" id="duration">
            7. DURATION
          </h5>
          <p>
            <span className="me-2">7.1</span> These General Conditions shall be
            in effect for the period defined in the Technical Proposal.
            <br />
            HIPHEN reserves the right to terminate these General Conditions,
            notably in the event of non-payment on the part of the Customer, and
            more generally in the event of non-compliance with these General
            Conditions.
          </p>
          <h5 className="text-info mb-4 mt-4" id="intellectual-property">
            8. INTELLECTUAL PROPERTY
          </h5>
          <p>
            <span className="me-2">8.1</span> Pursuant to these General
            Conditions, each party shall remain the owner of the intellectual
            property rights at its disposal; these General Conditions shall not
            be considered an assignment or licence to exploit these rights,
            subject to the provisions below.
            <br />
            Except for the exclusive purposes of information for personal and
            private use, any reproduction, distribution or use of any kind
            whatsoever of HIPHEN's intellectual property, without its express
            authorization, shall be prohibited.
          </p>
          <p>
            <span className="me-2">8.2</span> Subject to compliance with
            Paragraph 8.1 of these General Conditions, HIPHEN agrees transfer
            ownership of the Deliverables in return for the price set forth in
            the Technical Proposal. If the Deliverables are likely to be deemed
            to be intellectual property, HIPHEN agrees to transfer all of the
            economic rights linked to the Deliverables, as and when they are
            created, on a global and exclusive basis, in all languages and for
            the duration of the protections granted to intellectual property
            rights in accordance with French and foreign laws and international
            conventions, both for their initial purpose and for any future
            derived or different use. The Customer shall reserve the right to
            obtain, on its behalf, any existing protections for the Deliverables
            as well as any extension or renewal thereof.
            <br />
            The Customer shall be free to subcontract to any third party of its
            choice for the performance of any follow-up work on the
            Deliverables, in particular adjustments, arrangements,
            modifications, adaptations, and/or translations that it deems
            necessary.
          </p>
          <p>
            <span className="me-2">8.3</span> Subject to compliance with
            Paragraphs 8.1 and 8.2 herein and except with the prior written
            consent of the parties, the Customer agrees to the continued use of
            its data, obtained and/or used by HIPHEN in the context of the
            performance of the Services, after completion of the said Services
            and pursuant to these General Conditions. This data shall not
            include any personal data and shall only be used by HIPHEN. In no
            case shall it be communicated, for remuneration or free of charge,
            to any third party.
          </p>
          <p>
            <span className="me-2">8.4</span> “Results” refer to all
            information, data, descriptors, materials, knowhow, knowledge,
            methods, techniques and expertise, shared by the Customer with
            HIPHEN during the preparation and execution of the Technical
            Proposal. The Customer remains the sole owner of the Results and
            HIPHEN will obtain the (possible) intellectual property rights on
            the Results in order to perform Services and produce the
            Deliverables.
          </p>
          <p>
            <span className="me-2">8.5</span> The Customer acknowledges and
            agrees that the data processing software, algorithms, methods and
            techniques used to compute intermediary and final data outputs are
            the proprietary of HIPHEN and that the Customer will not have any
            ownership of it during or after termination of the Technical
            Proposal and General Conditions.
          </p>
          <h5 className="text-info mb-4 mt-4" id="warranty">
            9. WARRANTY
          </h5>
          <p>
            <span className="me-2">9.1</span> HIPHEN, as a specialist in its
            field, shall provide the Services and Deliverables in a professional
            manner and according to best practices and professional customs.
          </p>
          <p>
            <span className="me-2">9.2</span> The parties agree to comply with
            all laws, regulations, recommendations and professional practices
            that are applicable to them or that may be applicable to them.
          </p>
          <p>
            <span className="me-2">9.3</span> The parties shall purchase and
            maintain a valid policy of insurance covering any and all risks of
            liability, with regard to the other party and/or third parties, for
            damage of any kind, whether bodily, material or immaterial,
            consequential or not, caused as a result of or in connection with
            the performance of the Services.
          </p>
          <h5 className="text-info mb-4 mt-4" id="liability">
            10. LIABILITY
          </h5>
          <p>
            <span className="me-2">10.1</span> Pursuant to its obligations as
            set forth in Paragraph 2.1 of these General Conditions, HIPHEN's
            liability shall be limited to direct damages resulting from its own
            fault or negligence in the performance of the Services only, and
            shall be strictly limited, where applicable, to the amount of the
            price paid by the Customer as set forth in the Technical Proposal
            and restated in the Estimate.
          </p>
          <p>
            <span className="me-2">10.2</span> HIPHEN is responsible for hosting
            data online and locally to perform Services, but only from a
            location or access point of its choosing. HIPHEN may, at its
            discretion, change from one such location or access point to a
            different location or access point at its sole discretion. Customer
            acknowledges that:
            <ul>
              <li>
                For all customers, data processing will not be exclusively
                performed on U.S. servers;
              </li>
              <li>
                For some customers, including but not limited to those which are
                not in the U.S., data may not be fully hosted in the U.S. If
                Customer requests a different location or hosting provider,
                HIPHEN may charge additional costs, if it agrees to alter data
                hosting.
              </li>
            </ul>
          </p>
          <p>
            <span className="me-2">10.3</span> Data will be hosted for a period
            of eighteen (18) months upon the completion of the Technical
            Proposal. Data will be erased at the end of this period. Upon the
            completion of the technical Proposal, the Customer can demand at any
            point in time the immediate removal of all data deemed Confidential
            or not.
          </p>
          <p>
            <span className="me-2">10.4</span> HIPHEN has taken, and will
            continue to take, significant measures to provide a high level of
            data security. However, HIPHEN cannot guarantee the absolute
            security of information delivered to HIPHEN during the Customer use
            of Services and shall not be liable in any way for compromised data.
          </p>
          <p>
            <span className="me-2">10.5</span> In the event of any data loss or
            damage that is stored on or processed by HIPHEN, HIPHEN will use its
            commercially reasonable efforts to restore the lost or corrupted
            data from the last backup maintained by HIPHEN in accordance with
            HIPHEN’s disaster recovery procedure. Customer acknowledges and
            agrees that HIPHEN’s use of commercially reasonable efforts to
            restore lost or corrupted data will constitute Customer’s sole and
            exclusive remedy and HIPHEN’s sole liability in the event of any
            data loss or corruption. HIPHEN shall not be responsible for any
            loss, destruction or corruption of data caused by any third party.
          </p>
          <h5 className="text-info mb-4 mt-4" id="customer-responsabilities">
            11. CUSTOMER RESPONSABILITIES
          </h5>
          <p>
            <span className="me-2">11.1</span> In order for HIPHEN Services to
            be effective, Customer will at all times provide HIPHEN with
            Customer’s good faith cooperation and assistance and make available
            such information and personnel as may be reasonably required by
            HIPHEN.
          </p>
          <p>
            <span className="me-2">11.2</span> Customer is responsible for all
            activities conducted under its authorized user logins and for its
            authorized users’ compliance with the General Conditions. Customer
            will keep confidential and not disclose to any third parties, and
            will ensure that all authorized users keep confidential and do not
            disclose to any third parties, any user IDs, account numbers,
            passwords or other similar information for the Services.
          </p>
          <p>
            <span className="me-2">11.3</span> Customer will ensure that
            authorized users comply with the General Conditions. Customer will
            promptly notify HIPHEN of any suspected or alleged breach of the
            General Conditions and will cooperate with HIPHEN with respect to:
            <ul>
              <li>
                Any investigation by HIPHEN of any suspected or alleged breach
                of the General Conditions;
              </li>
              <li>Any action by HIPHEN to enforce the General Conditions.</li>
            </ul>
            HIPHEN may suspend or terminate Customer’s or any authorized user’s
            access to the Services upon notice to Customer in the event that
            HIPHEN reasonably determines that Customer or any authorized user
            breached these General Conditions.
          </p>
          <p>
            <span className="me-2">11.4</span> Upon the execution of these
            General Conditions, Customer will designate an individual to serve
            as its primary representative and contact for facilitating
            communications between Customer and HIPHEN (hereinafter referred to
            as the “Customer Representative”). The Customer Representative will
            have the responsibility and authority to make decisions, approve
            plans and grant requests on Customer’s behalf. Customer acknowledges
            and agrees that HIPHEN will be entitled to rely on all
            communications from and decisions of the Customer Representative.
            Customer may change its Customer Representative at any time by
            providing HIPHEN with at least five (5) days’ advance notice. If
            HIPHEN determines that the Customer Representative lacks sufficient
            experience and training to serve as the Customer Representative,
            HIPHEN may request that Customer appoints a replacement Customer
            Representative.
          </p>
          <p>
            <span className="me-2">11.5</span> Customer acknowledges that it
            will not enjoy all of the benefits of the Services unless Customer
            invests in experienced, qualified authorized users and provides such
            users with sufficient training to enable such users to utilize the
            Services as designed and to implement business processes and
            procedures that support the Services. Accordingly, Customer agrees
            to provide its authorized users with sufficient experience and
            training to use the Services and to understand the manner in which
            various inputs into the Services will affect the desired outputs and
            results of the Services .Customer acknowledges that a failure by
            Customer to provide experienced, qualified and sufficiently trained
            authorized users may result in increased fees for additional
            training and other services and limit or hinder HIPHEN’s ability to
            provide Services to Customer.
          </p>
          <p>
            <span className="me-2">11.6</span> Customer is responsible for the
            legality, reliability, integrity, accuracy, and quality of all data,
            information and materials that Customer (or any authorized user)
            captures and stores in, or enters (or has entered) into the
            Services.
          </p>
          <p>
            <span className="me-2">11.7</span> Customer acknowledges that the
            use of the Services is dependent upon access to telecommunications
            and Internet services such as DSL, cable, wireless or another
            high-speed Internet connection. Customer is responsible for
            procuring and maintaining the network connections that connect the
            Customer network to the Services, including, but not limited to,
            internet connectivity, « browser » software that supports protocols
            used by HIPHEN, including the Secure Socket Layer (SSL) protocol,
            and for complying with logon procedures for services that support
            such protocols.
          </p>
          <h5 className="text-info mb-4 mt-4" id="confidentiality">
            12. CONFIDENTIALITY
          </h5>
          <p>
            <span className="me-2">12.1</span> Any and all information, without
            regard to category, form or medium, that the parties shall have
            access to, pursuant to these General Conditions, shall be deemed to
            be “Confidential Information”.
          </p>
          <p>
            <span className="me-2">12.2</span> In any event, these General
            Conditions, the Technical Proposal and the Estimate are strictly
            confidential.
          </p>
          <p>
            <span className="me-2">12.3</span> Pursuant to these General
            Conditions, the parties may obtain knowledge or information
            belonging to the other party, which they both acknowledge. All of
            this information shall be deemed to be Confidential Information.
          </p>
          <p>
            <span className="me-2">12.4</span> The parties agree, subject to the
            applicable laws and regulations requiring the transmission of
            information to public authorities, for the duration of the General
            Conditions, and for a period of two (2) years following the
            expiration or termination of the General Conditions:
            <ul>
              <li>
                To consider any information which they receive from the other
                Party as Confidential Information, and apply the same protective
                measures as would be applied, where appropriate, to its own
                confidential information and, in any case, apply a sufficient
                level of protection in light of the category of the information;
              </li>
              <li>
                To only allow its employees, on a need to know basis, access to
                the said Confidential Information;
              </li>
              <li>
                Not to disclose, publish or transmit to third parties, any or
                all of the Confidential Information received from the other
                party, in any form whatsoever, without the prior written consent
                of the said party;
              </li>
              <li>
                To inform the other party as soon as possible of any loss or
                breach of Confidential Information;
              </li>
              <li>
                To use Confidential Information only for the performance of the
                Services.
              </li>
            </ul>
          </p>
          <p>
            <span className="me-2">12.5</span> Customer agrees to the use and
            sharing by HIPHEN of aggregated, anonymized or pseudonymized data
            such that it no longer identifies the Customer and that such data
            does not constitute Confidential Information.
          </p>
          <p>
            <span className="me-2">12.6</span> If Customer or any authorized
            users provide HIPHEN with any feedback, comments or suggestions for
            improvements or enhancements to the Services (hereinafter referred
            to as “Feedback”), Customer grants HIPHEN a royalty-free, worldwide,
            transferable, sub-licensable, irrevocable and perpetual license to
            use or incorporate into the Services any Feedback for any purpose
            without obligation to Customer (or to any authorized user) of any
            kind.
          </p>
          <h5 className="text-info mb-4 mt-4" id="personal-data">
            13. PERSONAL DATA
          </h5>
          <p>
            <span className="me-2">13.1</span> “Personal data” shall be defined
            as any information relating to a person or to a person who can be
            directly or indirectly identified by one or more details specific to
            them.
          </p>
          <p>
            <span className="me-2">13.2</span> The parties undertake to comply
            with the provisions of the applicable regulations on the protection
            of personal data, in particular those of European Regulation No.
            2016/679, known as the General Data Protection Regulation (GDPR).
          </p>
          <p>
            <span className="me-2">13.3</span> HIPHEN conserves collected
            personal data during the agreement and for a period of three (3)
            years upon termination of the General Conditions.
            <br />
            The data/files are collected for the purpose of contract management
            (including payment and recovery) and commercial operations
            (including commercial prospection) conducted by HIPHEN. Email
            marketing is only possible if the Customer has given express prior
            consent.
          </p>
          <p>
            <span className="me-2">13.4</span> Customer has the right to object,
            free of charge, the use by HIPHEN of this information for the
            purposes of commercial prospection by contacting{" "}
            <a href="mailto:privacy@hiphen-plant.com">
              privacy@hiphen-plant.com
            </a>
            .
          </p>
          <p>
            <span className="me-2">13.5</span> HIPHEN uses performance type
            cookies such as Google Analytics, a web-based analytics service
            provided by Google, Inc. (“Google”). In accordance with GDPR, HIPHEN
            uses Google Analytics in the interests of ensuring a continuous
            optimization of Services as well as to log statistics on the use of
            Services in order to improve our Services offering.
            <br />
            HIPHEN hereby informs the Customer that Google Analytics settings
            have been configured in a manner that Personal Data is only used to
            provide and maintain the analytics service. HIPHEN ensures the
            Customer that this configuration prevents the exploitation of
            Personal Data by Google.
            <br />
            The Customer hereby acknowledge and accepts the purposes and
            configuration abovementioned by using the Services.
          </p>
          <h5
            className="text-info mb-4 mt-4"
            id="compliance-with-anticorruption-regulations"
          >
            14. COMPLIANCE WITH ANTICORRUPTION REGULATIONS
          </h5>
          <p>
            <span className="me-2">14.1</span> The Customer agrees to comply
            with all French legal and regulatory provisions relating to the
            fight against corruption and influence peddling, in particular those
            contained in Book IV, Title III “ Infringements of State authority"
            (Articles 431-1 to 436-5) and Title IV "Attacks on public
            confidence" (Articles 441-1 to 446-4) of the Criminal Code, as well
            as any foreign regulations relating to the fight against corruption
            with an extraterritorial application, in particular American
            (Foreign Corrupt Practices Act) and British (UK Bribery Act)
            regulations to the extent that they are applicable.
          </p>
          <h5
            className="text-info mb-4 mt-4"
            id="force-majeure-unpredictability"
          >
            15. FORCE MAJEURE AND UNPREDICTABILITY
          </h5>
          <p>
            <span className="me-2">15.1</span> Neither party shall be liable for
            any non-performance of its obligations under these General
            Conditions resulting from a case of force majeure as defined by
            statutory law or case law as decided by the French courts and
            tribunals.
            <br />
            The non-performing party shall inform the other party, in writing,
            and without delay, and at the latest within five (5) calendar days
            of the occurrence of the event.
            <br />
            If the duration of the event exceeds one (1) month, either party
            shall have the right to terminate all or part of the General
            Conditions affected by the force majeure.
            <br />
            In terms of the application of this paragraph, a case of force
            majeure shall mean any situation that was unforeseeable at the time
            of the conclusion of these General Conditions, and which is
            irresistible in its execution, including, but limited to: a
            nationwide strike; riots; epidemics; fire or explosion; exceptional
            weather conditions; earthquake; civil war; and/or particularly
            serious acts of terrorism. <br />
          </p>
          <p>
            <span className="me-2">15.2</span> The parties, in their
            professional capacities, have decided to expressly rule out the
            intervention of the Courts by virtue of the application of Article
            1195 of the Civil Code.
          </p>
          <h5 className="text-info mb-4 mt-4" id="miscellaneous-provisions">
            16. MISCELLANEOUS PROVISIONS
          </h5>
          <p>
            <span className="me-2">16.1</span> Any notification under these
            General Conditions must be made in writing with service being made
            personally, by registered letter with return receipt requested, or
            by extrajudicial act to the address set forth above or to any other
            address which one party has notified the other of, pursuant to the
            methods specified previously.
          </p>
          <p>
            <span className="me-2">16.2</span> If one of the stipulations of the
            General Conditions is deemed null, illegal or void for any reason
            whatsoever, the remaining stipulations hereto shall remain
            enforceable in the same manner as if the stipulation deemed null,
            illegal or void had been deleted.
          </p>
          <p>
            <span className="me-2">16.3</span> No waiver, alteration,
            modification or cancellation relating to any of the provisions of
            the General Conditions shall be enforceable unless it is formalized
            in a written amendment signed by the parties. A party’s failure to
            require, in any or all circumstances, the execution of any of the
            provisions of these General Conditions shall in no way compromises
            its right to obtain compliance with them subsequently.
          </p>
          <p>
            <span className="me-2">16.4</span> The parties shall remain free to
            conclude similar contracts with third parties. The parties are
            hereby acting in their own name and on their own account as
            independent contractors. They shall have neither the power nor the
            authorization to bind the other party in any way whatsoever.
          </p>
          <p>
            <span className="me-2">16.5</span> HIPHEN expressly agrees that,
            without Customer’s prior written consent in each instance, it shall
            not disclose or otherwise identify Customer orally or in any of its
            advertising, publications, or other media that are displayed or
            disseminated to its customers or other parties, except that HIPHEN
            reserves the right to include Customer name(s) as a reference. If
            Customer provides such written consent for each such instance, then
            Customer grants to HIPHEN a worldwide, royalty-free, non-exclusive
            license for the Term to use its company logo, name for the limited
            purpose of displaying such information on HIPHEN’s website and in
            media specifically approved by Customer.
          </p>
          <p>
            <span className="me-2">16.6</span> The website used to access HIPHEN
            Services may contain hyperlinks to other sites on the Internet that
            are not owned or controlled by HIPHEN. HIPHEN does not endorse or
            assume any responsibility for any material on such sites, or any
            other material outside of the Service, that is accessed directly or
            indirectly by any such hyperlink.
          </p>
          <h5 className="text-info mb-4 mt-4" id="applicable-law-jurisdiction">
            17. APPLICABLE LAW AND JURISDICTION
          </h5>
          <p>
            <span className="me-2">17.1</span> These General Conditions shall be
            governed by French law. The parties expressly agree that the Vienna
            Convention (United Nations Convention on Contracts for the
            International Sale of Goods of April 11, 1980) shall not be
            applicable to these General Conditions.
            <br />
            In the event that a dispute arises regarding the validity,
            interpretation, execution or termination, for whatever reason, of
            these General Conditions, the parties agree to seek an amicable
            solution. If an amicable resolution of the said dispute cannot be
            found, the Commercial Court of Avignon (France) shall have sole
            jurisdiction over the matter.
          </p>
        </Col>
      </Row>
    </div>
  </div>
);
