import PropTypes from "prop-types";

export const SimpleMode = ({
  onClick,
  selectedContract,
  trial,
  refreshing,
}) => {
  return (
    <div
      className={`simple-mode p-1 gap-1 clickable ${
        refreshing ? "refreshing" : ""
      }`}
      onClick={onClick}
      data-tooltip-id="tooltip"
      data-tooltip-content="Change trial"
      data-tooltip-place="right"
    >
      {refreshing ? (
        <>Harvesting fresh data ...</>
      ) : (
        <>
          <span className="title ellipsis">
            <i className="fa fa-file-text discrete-icon" />{" "}
            {selectedContract.name}
          </span>
          <span className="d-flex justify-content-between align-items-center">
            <span className="ellipsis">
              <i className="fa fa-map-marker discrete-icon ms-1 me-1" />
              {trial.display_name}
            </span>
            <span>
              <i className="fa fa-calendar discrete-icon" />{" "}
              {trial.trial_dates.length}
            </span>
          </span>
        </>
      )}
    </div>
  );
};

SimpleMode.propTypes = {
  onClick: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  selectedContract: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  trial: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    trial_dates: PropTypes.array.isRequired,
  }).isRequired,
};
