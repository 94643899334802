import { AUC_COLOR, HIPHEN_GREEN, THEME } from "../../../../constants";
import {
  selectControlVarieties,
  selectFilteredFeaturesByDate,
} from "../../../../selectors/resultMap";

import { COMMON_PLOT_CONFIG } from "../../../constants";
import { Chart } from "./Chart";
import { ComponentPlaceholder } from "../../../components/componentPlaceholder/componentPlaceholder";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { PropertyName } from "../../../../components/PropertyName/PropertyName";
import { groupByKey } from "../../../utils";
import { mean } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const PLOT_STYLE = { width: "100%", height: "100%" };

// TODO: Optimize this after featuresByDate is refactored CLOV-392
const prepareData = (
  featuresByDate,
  controlVarieties,
  focusedElements,
  trait,
  aucModeChecked
) => {
  const displayData = [];
  const trueTrait = trait.isAddon ? trait.baseTrait : trait;

  focusedElements.forEach((element) => {
    const features = groupByKey(
      featuresByDate.filter((feature) => {
        return (
          feature.group === element &&
          feature.properties[trueTrait.technical_name] != null
        );
      }),
      "date"
    );

    const isControl = controlVarieties.includes(element);
    displayData.push({
      type: "scattergl",
      mode: "lines+markers",
      fill: trait.isAddon || aucModeChecked ? "tozeroy" : "none",
      meta: element,
      opacity: aucModeChecked ? 0.4 : 1,
      ...(isControl // If control variety: legend is bold and line is thicker
        ? {
            name: `<b>${element}</b>`,
            line: {
              width: 4,
            },
            marker: {
              size: 10,
            },
          }
        : {
            name: element,
            line: {
              width: 2,
            },
            marker: {
              size: 6,
            },
          }),
      x: Object.keys(features).sort(),
      y: Object.keys(features)
        .sort()
        .map((date) =>
          mean(
            features[date].map(
              (value) => value.properties[trueTrait.technical_name]
            )
          )
        ),
      hovertemplate: `Mean of ${trueTrait.name}: <b>%{y}</b><br>%{meta} on <b>%{x}</b><extra></extra>`,
    });
  });
  return displayData;
};

export const EvolutionChart = ({
  trait,
  aucModeChecked,
  aucDates,
  exportName,
}) => {
  const [refreshingFeaturesByDate, focusedVarieties, selectedDate] =
    useSelector(({ resultMap }) => [
      resultMap.refreshingFeaturesByDate,
      resultMap.focusedVarieties,
      resultMap.trial_date,
    ]);

  const featuresByDate = useSelector(selectFilteredFeaturesByDate);

  const controlVarieties = useSelector(selectControlVarieties);

  const displayData = useMemo(
    () =>
      prepareData(
        featuresByDate,
        controlVarieties,
        focusedVarieties,
        trait,
        aucModeChecked
      ),
    [focusedVarieties, trait, featuresByDate, controlVarieties, aucModeChecked]
  );

  /*
   * Compute layout to include shapes for AUC and current date
   */
  const layout = useMemo(() => {
    const shapes = [
      // Current date
      {
        type: "scattergl",
        x0: selectedDate,
        x1: selectedDate,
        y0: 0,
        y1: 1,
        yref: "paper",
        line: {
          color: HIPHEN_GREEN,
          width: 2,
        },
      },
    ];
    // Rectangle for AUC selection
    const aucLines = aucModeChecked
      ? aucDates.map((date) => {
          return {
            type: "line",
            x0: date,
            y0: 0,
            x1: date,
            yref: "paper",
            y1: 1,
            line: {
              color: AUC_COLOR,
              width: 4,
              dash: "dot",
            },
          };
        })
      : [];

    shapes.push(...aucLines);

    // Rectangle for AUC trait
    if (trait.aucDates)
      shapes.push({
        type: "scattergl",
        xref: "x",
        yref: "paper",
        x0: trait.aucDates[0],
        y0: 0,
        x1: trait.aucDates[1],
        y1: 1,
        fillcolor: AUC_COLOR,
        opacity: 0.4,
        line: {
          width: 0,
        },
      });

    return {
      shapes: shapes,
      autosize: true,
      showlegend: true,
      margin: { l: 40, r: 30, t: 0, b: 60 },
      plot_bgcolor: "transparent",
      paper_bgcolor: "white",
      yaxis: {
        gridcolor: THEME.indicators,
        fixedrange: true,
      },
      xaxis: {
        gridcolor: THEME.indicators,
        fixedrange: true,
      },

      font: { color: THEME.indicators, size: 15 },

      colorway: THEME.extendedPalette,
    };
  }, [aucDates, aucModeChecked, selectedDate, trait.aucDates]);

  const config = useMemo(
    () => ({
      toImageButtonOptions: {
        filename: exportName,
      },
      ...COMMON_PLOT_CONFIG,
    }),
    [exportName]
  );

  return (
    <Chart
      icon="fa-line-chart"
      title={
        trait.technical_name && (
          <span>
            Evolution of focused varieties: mean of{" "}
            <PropertyName
              showIcon
              showUnit
              technicalName={
                (trait.isAddon ? trait.baseTrait : trait).technical_name
              }
            />
          </span>
        )
      }
      isLoading={refreshingFeaturesByDate}
      missingData={featuresByDate.length === 0 && !refreshingFeaturesByDate}
    >
      {focusedVarieties.length > 0 ? (
        <Plot
          useResizeHandler
          data={displayData}
          layout={layout}
          config={config}
          style={PLOT_STYLE}
        />
      ) : (
        <ComponentPlaceholder
          text="Use the selection tool to select genotypes"
          icon="fa-search"
        />
      )}
    </Chart>
  );
};

EvolutionChart.propTypes = {
  aucModeChecked: PropTypes.bool.isRequired,
  aucDates: PropTypes.array.isRequired,
  exportName: PropTypes.string.isRequired,
  trait: PropTypes.object,
};
