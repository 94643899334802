/* eslint-disable react-hooks/exhaustive-deps */
import "./analyticsdashboard.css";

import React, { useEffect, useMemo, useState } from "react";
import {
  fetchFeaturesByDate,
  fetchResultMapFeatures,
  resetResultMapFeatures,
} from "../../actions/resultMap";
import { useDispatch, useSelector } from "react-redux";

import { BreederReportTemplate } from "./breederReportTemplate";
import { ComponentPlaceholder } from "../components/componentPlaceholder/componentPlaceholder";
import { Container } from "reactstrap";
import LoadingImg from "../../components/loading";
import NavbarComponent from "../../components/navbar";
import { fetchTraitGroups } from "../../actions/traits";
import { hasAnalyticsRole } from "../../users/rolesUtil";

/*
 * Analytics tool: powerful analytics dashboards
 * Single page app embedded in cloverfield to display intelligence and analytics
 * about calculated traits and crops fields.
 * Powerful modular templates can be implemented and embedded here
 */
export const AnalyticsDashboard = () => {
  const user = useSelector((state) => state.user);
  const resultMap = useSelector((state) => state.resultMap);
  const traitGroupsFetched = useSelector(
    (state) => state.traits.traitGroupsFetched
  );
  const dispatch = useDispatch();

  const [externalTrialSelected, setExternalTrialSelected] = useState(null);

  const refetch = (selectedDate) => {
    dispatch(fetchResultMapFeatures(resultMap.trial, selectedDate, false));
  };

  const sortedUserTrials = useMemo(
    () =>
      user.trials
        .filter((trial) =>
          hasAnalyticsRole(
            user.contracts?.find(({ id }) => id === trial.contract_id)?.roles
          )
        )
        .sort((trial1, trial2) =>
          trial1.display_name.localeCompare(trial2.display_name)
        ),
    [user]
  );

  // On trial change
  const onTrialChange = () => {
    if (resultMap?.trial && Object.keys(resultMap.featuresByDate).length === 0)
      //TODO: properties
      dispatch(fetchFeaturesByDate({ id: resultMap.trial.id }));
  };

  useEffect(() => {
    if (!traitGroupsFetched) dispatch(fetchTraitGroups());
    if (
      resultMap.selectedContract &&
      !hasAnalyticsRole(resultMap.selectedContract.roles)
    ) {
      resetResultMapFeatures();
      window.location.reload();
    }
  }, [resultMap.selectedContract]);

  return (
    <div className="wrapper">
      <NavbarComponent />
      {/* TODO add alerts */}
      {/* <AlertComponent /> */}

      <Container
        fluid
        className="page-content d-flex flex-column powerdash light-theme"
        key={externalTrialSelected} // Resets page on filter selection
      >
        {/* Change template here */}
        {resultMap.trial &&
        (!hasAnalyticsRole(resultMap.selectedContract.roles) ||
          resultMap.traitsListForMap.length === 0) ? (
          <>
            <ComponentPlaceholder text="Selected Trial is not available for analytics" />
          </>
        ) : (
          <BreederReportTemplate
            refetch={refetch}
            onTrialChange={onTrialChange}
            setExternalTrialSelected={setExternalTrialSelected}
            sortedUserTrials={sortedUserTrials}
            externalTrialSelected={externalTrialSelected}
          />
        )}
        {(resultMap.refreshing || !traitGroupsFetched) && (
          <div className="fullscreen_popup">
            <LoadingImg visible />
          </div>
        )}
      </Container>
    </div>
  );
};
