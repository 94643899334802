import {
  ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST,
  ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST,
  CREATE_FILTERING_PROFILE,
  DELETE_FILTERING_PROFILE,
  DELETE_FILTERING_PROFILE_FILTER,
  FULL_RESET_RESULT_MAP,
  REFRESHING_FEATURES_BY_DATE,
  REFRESHING_RESULT_MAP,
  REMOVE_FOCUSED_VARIETY,
  REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST,
  REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST,
  REMOVE_TRAIT_FROM_AUC_DATA,
  RESET_FILTERING_PROFILE,
  RESET_RESULT_MAP,
  SET_ATTRIBUTES_FILTER,
  SET_AUC_DATA,
  SET_COLOR_OPACITY,
  SET_COLOR_SCALE_STEP,
  SET_EXPERIMENTS_FILTER,
  SET_FAVORITE_VARIETIES,
  SET_FEATURES_BY_DATE,
  SET_FILTERING_PROFILE,
  SET_FILTERING_PROFILES,
  SET_FILTERING_PROFILE_FILTER,
  SET_FILTERING_PROFILE_SCOPE,
  SET_FOCUSED_VARIETIES,
  SET_LAYER_FILTER,
  SET_MODALITIES_FILTER,
  SET_NEW_FEATURE_PROPERTIES,
  SET_RESULT_MAP_FEATURES,
  SET_SELECTED_CONTRACT,
  SET_SELECTED_PLOT_ID,
  SET_SELECTED_TRAIT,
  SET_TRAITS,
  SET_VISUALIZED_VARIETIES,
  UPDATE_FILTERING_PROFILE,
} from "./actionTypes";
import { hasExperimentsRole, hasFeaturesRole } from "../users/rolesUtil";
import {
  requestFeaturesPropertiesByDate,
  requestFetchClientExperiments,
  requestFetchFilteringProfiles,
  requestFetchPlotRatings,
  requestFetchResultMapFeatures,
  requestFetchTraitGroups,
  requestUpdateFilteringProfile,
} from "../services/backendRequests";

import { MAX_GENOTYPE_SELECTION } from "../powerdash/constants";
import { addDangerAlert } from "./alerts";

export function setResultMapFeatures(
  contract,
  trial,
  features,
  traits,
  trialDate,
  experiments,
  plot_ratings,
  filteringProfiles
) {
  return {
    type: SET_RESULT_MAP_FEATURES,
    contract,
    trial,
    features,
    traits,
    trialDate,
    experiments,
    plot_ratings,
    filteringProfiles,
  };
}

export function updateFeatureProperties(newProperties) {
  return {
    type: SET_NEW_FEATURE_PROPERTIES,
    newProperties,
  };
}

function setFeaturesByDate(featuresByDate, traits) {
  return (dispatch) => {
    dispatch({
      type: SET_FEATURES_BY_DATE,
      featuresByDate,
      traits,
    });
  };
}

export function setSelectedPlotId(selectedPlotId) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PLOT_ID,
      selectedPlotId,
    });
  };
}

export function setSelectedTrait(trait) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_TRAIT,
      trait,
    });
  };
}

export function setColorScaleStep(step) {
  return (dispatch) => {
    dispatch({
      type: SET_COLOR_SCALE_STEP,
      step,
    });
  };
}

export function setColorOpacity(opacity) {
  return (dispatch) => {
    dispatch({
      type: SET_COLOR_OPACITY,
      opacity,
    });
  };
}

function refreshingResultMap() {
  return {
    type: REFRESHING_RESULT_MAP,
  };
}

function refreshingFeaturesByDate() {
  return {
    type: REFRESHING_FEATURES_BY_DATE,
  };
}

export function resetResultMapFeatures() {
  return (dispatch) => {
    dispatch({
      type: RESET_RESULT_MAP,
    });
  };
}

/**
 * Remove company object from store on logout
 */
export function fullResetResultMap() {
  return (dispatch) => {
    dispatch({
      type: FULL_RESET_RESULT_MAP,
    });
  };
}

export function fetchResultMapFeatures(
  trial,
  trialDate,
  refreshExperiments = true
) {
  return async (dispatch, getState) => {
    // call refreshing to display spinner because it could be long to get data
    dispatch(refreshingResultMap());

    const contractInfo = getState().user.contracts.find(
      (contract) => contract.id === trial?.contract_id
    );

    try {
      let features = [];
      let plot_ratings = [];
      let filteringProfiles = [];
      let experiments;

      if (hasFeaturesRole(contractInfo?.roles)) {
        if (!getState().traits.all.length) {
          const trait_groups = await requestFetchTraitGroups(getState().user);
          dispatch({ type: SET_TRAITS, traitGroups: trait_groups });
        }

        await Promise.all([
          requestFetchResultMapFeatures(getState().user, trial, trialDate),
          requestFetchPlotRatings(getState().user, trial, trialDate),
          requestFetchFilteringProfiles(getState().user, trial),
        ]).then(([featureData, plotRatingData, filteringProfilesData]) => {
          features = featureData;
          plot_ratings = plotRatingData;
          filteringProfiles = filteringProfilesData;
        });
      }

      if (refreshExperiments && hasExperimentsRole(contractInfo?.roles))
        experiments = await requestFetchClientExperiments(
          trial,
          getState().user
        );

      dispatch(
        setResultMapFeatures(
          contractInfo,
          trial,
          features,
          getState().traits.all,
          trialDate,
          experiments,
          plot_ratings,
          filteringProfiles
        )
      );
    } catch (err) {
      dispatch(addDangerAlert(err));
    }
  };
}

export function setFilteringProfileScope(scope) {
  return (dispatch) => {
    dispatch({
      type: SET_FILTERING_PROFILE_SCOPE,
      scope,
    });
  };
}

export function setFilteringProfileFilter(filter) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_FILTERING_PROFILE_FILTER,
      filter,
    });
    // Persist filtering profile
    requestUpdateFilteringProfile(
      getState().user,
      getState().resultMap.filteringProfile
    );
  };
}

// Removes applied filter on trait for given date
export function deleteFilteringProfileFilter(date) {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_FILTERING_PROFILE_FILTER,
      date,
    });
    requestUpdateFilteringProfile(
      getState().user,
      getState().resultMap.filteringProfile
    );
  };
}

export function setFilteringProfiles(filteringProfiles) {
  return (dispatch) => {
    dispatch({
      type: SET_FILTERING_PROFILES,
      filteringProfiles,
    });
  };
}

export function createFilteringProfile(filteringProfile) {
  return (dispatch) => {
    dispatch({
      type: CREATE_FILTERING_PROFILE,
      filteringProfile,
    });
  };
}

export function deleteFilteringProfile(filteringProfile) {
  return (dispatch) => {
    dispatch({
      type: DELETE_FILTERING_PROFILE,
      filteringProfile,
    });
  };
}

export function setFilteringProfile(filteringProfile) {
  return (dispatch) => {
    dispatch({
      type: SET_FILTERING_PROFILE,
      filteringProfile,
    });
  };
}

export function resetFilteringProfile() {
  return (dispatch) => {
    dispatch({
      type: RESET_FILTERING_PROFILE,
    });
  };
}

export function addGroupToFilteringProfileBlacklist(group) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST,
      group,
    });
    try {
      await requestUpdateFilteringProfile(
        getState().user,
        getState().resultMap.filteringProfile
      );
    } catch {
      dispatch({
        type: REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST,
        group,
      });
    }
  };
}

export function removeGroupFromFilteringProfileBlacklist(group) {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST,
      group,
    });
    try {
      await requestUpdateFilteringProfile(
        getState().user,
        getState().resultMap.filteringProfile
      );
    } catch {
      dispatch({
        type: ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST,
        group,
      });
    }
  };
}

export function addGroupToFilteringProfileWhitelist(group) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST,
      group,
    });
    try {
      await requestUpdateFilteringProfile(
        getState().user,
        getState().resultMap.filteringProfile
      );
    } catch {
      dispatch({
        type: REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST,
        group,
      });
    }
  };
}

export function removeGroupFromFilteringProfileWhitelist(group) {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST,
      group,
    });
    try {
      await requestUpdateFilteringProfile(
        getState().user,
        getState().resultMap.filteringProfile
      );
    } catch {
      dispatch({
        type: ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST,
        group,
      });
    }
  };
}

export function updateFilteringProfile(updatedProfile) {
  return async (dispatch, getState) => {
    const filteringProfile = getState().resultMap.filteringProfile;
    dispatch({
      type: UPDATE_FILTERING_PROFILE,
      filteringProfile: updatedProfile,
    });

    try {
      await requestUpdateFilteringProfile(getState().user, updatedProfile);
    } catch {
      dispatch({
        type: UPDATE_FILTERING_PROFILE,
        filteringProfile: filteringProfile,
      });
    }
  };
}

export function setExperimentsFilter(experiments) {
  return (dispatch) => {
    dispatch({
      type: SET_EXPERIMENTS_FILTER,
      experiments,
    });
  };
}

export function setModalitiesFilter(modalities) {
  return (dispatch) => {
    dispatch({
      type: SET_MODALITIES_FILTER,
      modalities,
    });
  };
}

export function setFocusedVarieties(focusedVarieties) {
  return (dispatch) => {
    dispatch({
      type: SET_FOCUSED_VARIETIES,
      focusedVarieties,
    });
  };
}

export function addFocusedVarieties(focusedVarieties) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_FOCUSED_VARIETIES,
      focusedVarieties: Array.from(
        new Set(
          getState().resultMap.focusedVarieties.concat(
            focusedVarieties.slice(0, MAX_GENOTYPE_SELECTION)
          )
        )
      ),
    });
  };
}

export function removeFocusedVariety(variety) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FOCUSED_VARIETY,
      variety,
    });
  };
}

export function setFavoriteVarieties(favoriteVarieties) {
  return (dispatch) => {
    dispatch({
      type: SET_FAVORITE_VARIETIES,
      favoriteVarieties,
    });
  };
}

export function setVisualizedVarieties(visualizedVarieties) {
  return (dispatch) => {
    dispatch({
      type: SET_VISUALIZED_VARIETIES,
      visualizedVarieties,
    });
  };
}

export function setLayerFilter(layer) {
  return (dispatch) => {
    dispatch({
      type: SET_LAYER_FILTER,
      layer,
    });
  };
}

export function setAttributes(attributes) {
  return (dispatch) => {
    dispatch({
      type: SET_ATTRIBUTES_FILTER,
      attributes,
    });
  };
}

export function fetchFeaturesByDate(site) {
  return (dispatch, getState) => {
    dispatch(refreshingFeaturesByDate());
    requestFeaturesPropertiesByDate(getState().user, site)
      .then((featuresByDate) => {
        // Prevent from overriding data
        if (getState().resultMap.featuresByDate.length === 0)
          dispatch(setFeaturesByDate(featuresByDate, getState().traits.all));
      })
      .catch((err) => dispatch(addDangerAlert(err)));
  };
}

export function setSelectedContract(contract) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CONTRACT,
      contract,
    });
  };
}

export function setAucData(aucData) {
  return (dispatch) => {
    dispatch({
      type: SET_AUC_DATA,
      aucData,
    });
  };
}

export function removeTraitFromAucData(traitLabel) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TRAIT_FROM_AUC_DATA,
      traitLabel,
    });
  };
}
