import { useCallback, useEffect, useRef, useState } from "react";

import { DotFalling } from "../components/dotFalling";
import { FigureZoom } from "../components/FigureZoom";
import { PLOT_VIEW_MODES } from "../constants";
import { PlotModal } from "./PlotModal";
import PropTypes from "prop-types";
import { PropertiesMasonry } from "../components/PropertiesMasonry/PropertiesMasonry";
import { RadarChart } from "../powerdash/analyticsDashboard/components/charts/RadarChart";
import no_image from "../static/img/microplot_placeholder.png";
import { requestFeaturePresignedUrl } from "../services/backendRequests";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { useSelector } from "react-redux";

const BATCH_SIZE = 3;

const ImageHistory = ({
  trial,
  images,
  currentIndex,
  trial_date,
  handleImageClick,
  setImages,
  fetchImage,
}) => {
  // Detect when to fetch
  const [ref, { entry }] = useIntersectionObserver();
  const bottomComponentIsVisible = entry?.isIntersecting;

  const imagesAreLoading = Object.values(images).some(
    ({ isLoading }) => isLoading
  );

  // Fetch batch each time the function changes (i.e on currentIndex changing)
  useEffect(() => {
    const fetchBatch = async () => {
      const nextIndex = currentIndex.current + BATCH_SIZE;
      const datesToFetch = trial.trial_dates.slice(
        currentIndex.current,
        nextIndex
      );

      setImages((prevState) => ({
        ...prevState,
        ...datesToFetch.reduce(
          (dates, date) => ({
            ...dates,
            [date]: { url: null, isLoading: true },
          }),
          {}
        ),
      }));

      for (const date of datesToFetch) await fetchImage(date);
    };

    // If we reached bottom and a batch isnt currently fetching
    // we do fetch the batch and increase current index of BATCH_SIZE
    if (bottomComponentIsVisible && !imagesAreLoading) {
      fetchBatch();
      currentIndex.current =
        currentIndex.current + BATCH_SIZE < trial.trial_dates.length
          ? currentIndex.current + BATCH_SIZE
          : trial.trial_dates.length;
    }
  }, [
    bottomComponentIsVisible,
    currentIndex,
    fetchImage,
    imagesAreLoading,
    setImages,
    trial.trial_dates,
  ]);

  return (
    <div className="image-history">
      {trial.trial_dates.slice(0, currentIndex.current).map((date) => (
        <div
          key={date}
          className={`img-wrapper clickable ${
            trial_date === date ? "current" : ""
          }`}
          onClick={() => handleImageClick(date)}
        >
          {images[date]?.isLoading ? (
            <div className="img-skeleton"></div>
          ) : (
            <img src={images[date]?.url} alt={date} />
          )}
          <span className="date">{date}</span>
        </div>
      ))}
      <div className="scroll-end" ref={ref}>
        {imagesAreLoading ? (
          <DotFalling />
        ) : (
          <>
            <i className="fa fa-check-circle" /> You have reached the end
          </>
        )}
      </div>
    </div>
  );
};

const Tabs = ({ mode, setMode }) => {
  return (
    <div className="plot-view-tabs">
      <div
        className={`plot-view-tabs-item ${
          mode === PLOT_VIEW_MODES.PROPERTIES ? "active" : ""
        }`}
        onClick={() => setMode(PLOT_VIEW_MODES.PROPERTIES)}
      >
        Properties
      </div>

      <div
        className={`plot-view-tabs-item ${
          mode === PLOT_VIEW_MODES.IMAGES ? "active" : ""
        }`}
        onClick={() => setMode(PLOT_VIEW_MODES.IMAGES)}
      >
        Image history
      </div>
    </div>
  );
};

export const PlotView = ({ feature, trial, trial_date }) => {
  const user = useSelector((state) => state.user);

  const [images, setImages] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [mode, setMode] = useState(PLOT_VIEW_MODES.PROPERTIES);

  const currentIndex = useRef(0);

  // Used to reset state whenever the selected plot changes
  const [prevFeature, setPrevFeature] = useState(null);
  if (prevFeature !== feature) {
    setImages({});
    currentIndex.current = 0;
    setPrevFeature(feature);
  }

  // Fetch image at this date
  const fetchImage = useCallback(
    (date) => {
      return requestFeaturePresignedUrl(trial, feature, date, user)
        .then((res) => {
          setImages((currentState) => ({
            ...currentState,
            [date]: { url: res.url, isLoading: false },
          }));
        })
        .catch((err) => {
          setImages((currentState) => ({
            ...currentState,
            [date]: { url: no_image, isLoading: false },
          }));
        });
    },
    [feature, trial, user]
  );

  // Fetch current image to display in zoom view
  useEffect(() => {
    fetchImage(trial_date);
  }, [fetchImage, trial_date]);

  const [plotModalIsOpen, setPlotModalOpen] = useState(false);

  const handleImageClick = (date) => {
    setSelectedDate(date);
    setPlotModalOpen(true);
  };
  const handleClosePlotModal = () => {
    setPlotModalOpen(false);
  };

  return (
    <>
      <PlotModal
        src={images[selectedDate]?.url}
        isOpen={plotModalIsOpen}
        toggle={handleClosePlotModal}
      />
      <div className="plot-view">
        <span className="plot-view-title">{feature.displayId}</span>

        <div className="radar-chart-wrapper">
          <RadarChart
            data={feature}
            exportName={`Radar chart ${trial.name} ${feature.displayId} ${trial_date}`}
          />
        </div>

        <div className="plot-explorer">
          {images[trial_date] != null ? (
            <FigureZoom
              src={images[trial_date].url}
              onClick={() => handleImageClick(trial_date)}
            />
          ) : (
            <div className="img-skeleton" />
          )}
        </div>

        {/* Tabs section */}
        <Tabs mode={mode} setMode={setMode} />
        {mode === PLOT_VIEW_MODES.PROPERTIES && (
          <PropertiesMasonry feature={feature} />
        )}
        {mode === PLOT_VIEW_MODES.IMAGES && (
          <ImageHistory
            trial={trial}
            images={images}
            currentIndex={currentIndex}
            trial_date={trial_date}
            handleImageClick={handleImageClick}
            setImages={setImages}
            fetchImage={fetchImage}
          />
        )}
      </div>
    </>
  );
};

Tabs.propTypes = {
  mode: PropTypes.oneOf(Object.values(PLOT_VIEW_MODES)).isRequired,
  setMode: PropTypes.func.isRequired,
};

ImageHistory.propTypes = {
  trial: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired,
  currentIndex: PropTypes.object.isRequired,
  trial_date: PropTypes.string.isRequired,
  handleImageClick: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  fetchImage: PropTypes.func.isRequired,
};

PlotView.propTypes = {
  feature: PropTypes.object.isRequired,
  trial: PropTypes.object.isRequired,
  trial_date: PropTypes.string.isRequired,
};
