// General
export const RESET = "RESET";
export const TOOGLE_FREEZE_INTERFACE = "SET_FREEZE_INTERFACE";

// Alerts
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";

// Current user
export const LOGIN_MESSAGE = "LOGIN_MESSAGE";
export const SIGNUP_MESSAGE = "SIGNUP_MESSAGE";
export const SET_USER = "SET_USER";
export const SET_USER_CONTRACTS_LOADING = "SET_USER_CONTRACTS_LOADING";
export const SET_USER_CONTRACTS_TRIALS = "SET_USER_CONTRACTS_TRIALS";
export const SET_USER_CONTRACTS = "SET_USER_CONTRACTS";
export const SET_USER_SIDEBAR_COLLAPSE = "SET_USER_SIDEBAR_COLLAPSE";
export const RESET_USER_CONTRACTS_TRIALS = "RESET_USER_CONTRACTS_TRIALS";

// Users admin
export const SET_USERS = "SET_USERS";
export const USER_UPDATE_ALERT = "USER_UPDATE_ALERT";

// Companies admin
export const SET_COMPANIES = "SET_COMPANIES";
export const COMPANY_UPDATE_ALERT = "COMPANY_UPDATE_ALERT";

// Contract - Sites admin
export const RESET_SITES = "RESET_SITES";
export const SET_SITES = "SET_SITES";
export const SITE_UPDATE_ALERT = "SITE_UPDATE_ALERT";
export const SITE_DISPATCH_DELETION = "SITE_DISPATCH_DELETION";
export const SET_SITE_TRIAL = "SET_SITE_TRIAL";
export const SET_REFRESHING_SITE_TRIAL = "SET_REFRESHING_SITE_TRIAL";
export const RESET_SITE_TRIAL = "RESET_SITE_TRIAL";

// Traits admin
export const SET_TRAITS = "SET_TRAITS";
export const TRAIT_UPDATE_ALERT = "TRAIT_UPDATE_ALERT";

// Treatments
export const RESET_TREATMENTS = "RESET_TREATMENTS";
export const SET_TREATMENT = "SET_TREATMENT";
export const SET_ALL_TREATMENTS = "SET_ALL_TREATMENTS";
export const SET_TREATMENTS = "SET_TREATMENTS";
export const UPDATE_TREATMENT = "UPDATE_TREATMENT";

// Treatment channel
export const SET_SUBSCRIPTION_CHANNEL = "SET_SUBSCRIPTION_CHANNEL";

// Treatment chains
export const SET_CONFIGURATION_MODE = "SET_CONFIGURATION_MODE";
export const SET_TREATMENT_CHAINS = "SET_TREATMENT_CHAINS";
export const SET_TREATMENT_CHAIN = "SET_TREATMENT_CHAIN";
export const UPDATE_TREATMENT_CHAIN_INPUT_PARAMETER =
  "UPDATE_TREATMENT_CHAIN_INPUT_PARAMETER";
export const UPDATE_TREATMENT_CHAIN_ARCHIVAL =
  "UPDATE_TREATMENT_CHAIN_ARCHIVAL";
export const UPDATE_TREATMENT_CHAIN_CONFIGURATION =
  "UPDATE_TREATMENT_CHAIN_CONFIGURATION";

// Create treatment
export const RESET_UPLOADING = "RESET_UPLOADING";
export const SET_SERVICE_FOR_CREATION = "SET_SERVICE_FOR_CREATION";
export const SET_TREATMENT_CHAIN_FOR_CREATION =
  "SET_TREATMENT_CHAIN_FOR_CREATION";
export const SET_UPLOADING = "SET_UPLOADING";
export const TREATMENT_SUBMITTED = "TREATMENT_SUBMITTED";
export const UPDATE_NEW_ATTRIBUTES = "UPDATE_NEW_ATTRIBUTES";

// Files upload
export const INC_UPLOAD_PROGRESS = "INC_UPLOAD_PROGRESS";
export const RESET_DROP_FILES = "RESET_DROP_FILES";
export const RESET_UPLOAD_PROGRESS = "RESET_UPLOAD_PROGRESS";
export const SET_DROP_FILES = "SET_DROP_FILES";

// Data table (prefixes)
export const REFRESHING = "REFRESHING";
export const SET_SORT_PARAMS = "SET_SORT_PARAMS";

// Password reset
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

// Contracts
export const ADD_NEW_CONTRACT = "ADD_NEW_CONTRACT";
export const SET_CONTRACTS = "SET_CONTRACTS";

// Provisions
export const RESET_PROVISION = "RESET_PROVISION";
export const SET_PROVISIONS = "SET_PROVISIONS";
export const SET_PROVISION = "SET_PROVISION";
export const UPDATE_PROVISION_EDITION = "UPDATE_PROVISION_EDITION";
export const UPDATE_PROVISION_SERVICE = "UPDATE_PROVISION_SERVICE";
export const UPDATE_PROVISION_STATUS = "UPDATE_PROVISION_STATUS";
export const ADD_NEW_PROVISION_SERVICE = "ADD_NEW_PROVISION_SERVICE";

// Systems
export const SET_SYSTEMS = "SET_SYSTEMS";

// Dashboard
export const SET_ABORTED_TREATMENTS = "SET_ABORTED_TREATMENTS";
export const SET_PENDING_PROVISIONS = "SET_PENDING_PROVISIONS";

// Consumption
export const SET_CONSUMPTION = "SET_CONSUMPTION";
export const SET_MONTH_FOR_CONSUMPTION = "SET_MONTH_FOR_CONSUMPTION";

// Client
export const RESET_CLIENT_PROVISION = "RESET_CLIENT_PROVISION";
export const SET_CLIENT_PROVISIONS = "SET_CLIENT_PROVISIONS";
export const SET_CLIENT_PROVISION = "SET_CLIENT_PROVISION";
export const SET_REFRESHING_CLIENT_PROVISIONS =
  "SET_REFRESHING_CLIENT_PROVISIONS";

// Company
export const SET_COMPANY_CONTRACTS = "SET_COMPANY_CONTRACTS";
export const RESET_COMPANY = "RESET_COMPANY";
export const RESET_COMPANY_CONTRACTS = "RESET_COMPANY_CONTRACTS";
export const COMPANY_CONTRACT_UPDATE_ALERT = "COMPANY_CONTRACT_UPDATE_ALERT";

// ResultMap
export const SET_SELECTED_PLOT_ID = "SET_SELECTED_PLOT_ID";
export const SET_RESULT_MAP_FEATURES = "SET_RESULT_MAP_FEATURES";
export const RESET_RESULT_MAP = "RESET_RESULT_MAP";
export const REFRESHING_RESULT_MAP = "REFRESHING_RESULT_MAP";
export const FULL_RESET_RESULT_MAP = "FULL_RESET_RESULT_MAP";
export const SET_SELECTED_TRAIT = "SET_SELECTED_TRAIT";
export const SET_COLOR_SCALE_STEP = "SET_COLOR_SCALE_STEP";
export const SET_COLOR_OPACITY = "SET_COLOR_OPACITY";
export const SET_FEATURES_BY_DATE = "SET_FEATURES_BY_DATE";
export const REFRESHING_FEATURES_BY_DATE = "REFRESHING_FEATURES_BY_DATE";
export const SET_EXPERIMENTS_FILTER = "SET_EXPERIMENTS_FILTER";
export const SET_MODALITIES_FILTER = "SET_MODALITIES_FILTER";
export const SET_LAYER_FILTER = "SET_LAYER_FILTER";
export const SET_ATTRIBUTES_FILTER = "SET_ATTRIBUTES_FILTER";
export const CREATE_FILTERING_PROFILE = "CREATE_FILTERING_PROFILE";
export const DELETE_FILTERING_PROFILE = "DELETE_FILTERING_PROFILE";
export const SET_FILTERING_PROFILE = "SET_FILTERING_PROFILE";
export const SET_FILTERING_PROFILES = "SET_FILTERING_PROFILES";
export const SET_FILTERING_PROFILE_SCOPE = "SET_FILTERING_PROFILE_SCOPE";
export const UPDATE_FILTERING_PROFILE = "UPDATE_FILTERING_PROFILE";
export const SET_FILTERING_PROFILE_FILTER = "SET_FILTERING_PROFILE_FILTER";
export const SET_NEW_FEATURE_PROPERTIES = "SET_NEW_FEATURE_PROPERTIES";
export const DELETE_FILTERING_PROFILE_FILTER =
  "DELETE_FILTERING_PROFILE_FILTER";
export const RESET_FILTERING_PROFILE = "RESET_FILTERING_PROFILE";
export const ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST =
  "ADD_GROUP_TO_FILTERING_PROFILE_BLACKLIST";
export const REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST =
  "REMOVE_GROUP_FROM_FILTERING_PROFILE_BLACKLIST";
export const ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST =
  "ADD_GROUP_TO_FILTERING_PROFILE_WHITELIST";
export const REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST =
  "REMOVE_GROUP_FROM_FILTERING_PROFILE_WHITELIST";
export const SET_FOCUSED_VARIETIES = "SET_FOCUSED_VARIETIES";
export const REMOVE_FOCUSED_VARIETY = "REMOVE_FOCUSED_VARIETY";
export const SET_FAVORITE_VARIETIES = "SET_FAVORITE_VARIETIES";
export const SET_VISUALIZED_VARIETIES = "SET_VISUALIZED_VARIETIES";
export const SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT";
export const SET_AUC_DATA = "SET_AUC_DATA";
export const REMOVE_TRAIT_FROM_AUC_DATA = "REMOVE_TRAIT_FROM_AUC_DATA";

// Crop
export const SET_CROPS = "SET_CROPS";
export const ADD_NEW_CROP = "ADD_NEW_CROP";
export const UPDATE_CROP = "UPDATE_CROP";
export const DELETE_CROP = "DELETE_CROP";

// Experiment
export const SET_SITE_EXPERIMENTS = "SET_EXPERIMENT";
// Modality
export const SET_SITE_MODALITIES = "SET_MODALITIES";
