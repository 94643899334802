import "./focusList.css";

import {
  AD_MAX_VISUALIZED_ELEMENTS,
  HIPHEN_GREEN,
} from "../../../../constants";
import { Badge, Button, Input } from "reactstrap";
import {
  addFocusedVarieties,
  removeFocusedVariety,
  setFavoriteVarieties,
  setFocusedVarieties,
  setVisualizedVarieties,
} from "../../../../actions/resultMap";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ComplexSearch } from "../../../components/complexSearch/ComplexSearch";
import { ExpandButton } from "../../../components/expandButton/expandButton";
import { FocusListItem } from "./FocusListItem";
import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import { removeElementFromArray } from "../../utils";
import { selectControlVarieties } from "../../../../selectors/resultMap";

/* FocusList displays the focused elements selected
 * It is a list group with additionnal controls to
 * interact with other components from the template
 * focusedElements are string names: generic (can be: genotypes / names / treaments ..)
 */
export const FocusList = ({
  groups,
  getColor,
  expandFocusView,
  setExpandFocusView,
  propertyFilter,
  setPropertyFilter,
  selectedFeature,
  openPlotRatingModal,
  userCanRatePlots,
}) => {
  const controlVarieties = useSelector(selectControlVarieties);
  const [features, focusedVarieties, visualizedVarieties, favoriteVarieties] =
    useSelector(({ resultMap }) => [
      resultMap.features,
      resultMap.focusedVarieties,
      resultMap.visualizedVarieties,
      resultMap.favoriteVarieties,
    ]);

  const dispatch = useDispatch();

  // For show button: toggle visualisation of element, handles visualised list
  // Size limited to DAHLIA_MAX_VISUALIZED_ELEMENTS
  const handleSwitchVisible = useCallback(
    (gen) => () => {
      if (visualizedVarieties.includes(gen))
        dispatch(
          setVisualizedVarieties(
            removeElementFromArray(visualizedVarieties, gen)
          )
        );
      else if (visualizedVarieties.length < AD_MAX_VISUALIZED_ELEMENTS)
        dispatch(setVisualizedVarieties([...visualizedVarieties, gen]));
    },
    [dispatch, visualizedVarieties]
  );

  // Pinned elements to be persistent on clear
  const handleSwitchFavorite = useCallback(
    (gen) => () => {
      if (favoriteVarieties.includes(gen))
        dispatch(
          setFavoriteVarieties(removeElementFromArray(favoriteVarieties, gen))
        );
      else dispatch(setFavoriteVarieties([...favoriteVarieties, gen]));
    },
    [favoriteVarieties, dispatch]
  );

  const handleRemove = useCallback(
    (gen) => () => {
      dispatch(removeFocusedVariety(gen));
    },
    [dispatch]
  );

  // Removes all visualized elements
  const clearVisualisation = () => {
    dispatch(setVisualizedVarieties([]));
  };

  // Removes every element except pinned ones
  const clearList = () => {
    clearVisualisation();
    dispatch(setFocusedVarieties(favoriteVarieties));
  };

  const content = useMemo(
    () => ({
      varieties: {
        options: groups
          .filter((grp) => !focusedVarieties.includes(grp))
          .map((grp) => ({
            label: grp,
            value: grp,
            renderBullet: (
              <i className="fa fa-pagelines" style={{ color: HIPHEN_GREEN }} />
            ),
          })),
      },
      "Control Varieties": {
        options: controlVarieties
          .filter((grp) => !focusedVarieties.includes(grp))
          .map((grp) => ({
            label: grp,
            value: grp,
            renderBullet: (
              <i className="fa fa-pagelines" style={{ color: "brown" }} />
            ),
          })),
      },
      plots: {
        options: features
          .filter((feature) => !focusedVarieties.includes(feature.group))
          .map((feature) => ({
            label: feature.displayId,
            value: feature.group,
            renderBullet: (
              <i className="fa fa-square" style={{ color: HIPHEN_GREEN }} />
            ),
          })),
      },
    }),
    [features, focusedVarieties, groups, controlVarieties]
  );

  return (
    <div className="powerdash-component focus-list">
      <div className="focus-list-controls">
        <ComplexSearch
          content={content}
          placeholder="Search among varieties and plots..."
          handleClickDefault={(value) => {
            dispatch(addFocusedVarieties([value]));
          }}
          disabled={!groups.length}
          inverted={!expandFocusView}
        />

        <Badge pill className="focused-elements-badge">
          {focusedVarieties.length}
        </Badge>
        <ExpandButton
          expanded={expandFocusView}
          setExpanded={setExpandFocusView}
        />
      </div>
      <Virtuoso
        data={focusedVarieties}
        computeItemKey={(_, gen) => gen}
        itemContent={(_, gen) => (
          <FocusListItem
            name={gen}
            isControl={controlVarieties.includes(gen)}
            switchVisible={handleSwitchVisible(gen)}
            switchFavorite={handleSwitchFavorite(gen)}
            onRemove={handleRemove(gen)}
            visualIndex={visualizedVarieties.indexOf(gen)}
            isFavorite={favoriteVarieties.includes(gen)}
            color={getColor(gen)}
          />
        )}
      />
      {selectedFeature && !focusedVarieties.includes(selectedFeature.group) && (
        <Badge
          className="focus-list-item-name pending-selection"
          data-tooltip-id="tooltip"
          data-tooltip-content={`Click to add variety of the selected feature on plot map (${selectedFeature.id})`}
          data-tooltip-place="top"
          data-text-color="white"
          onClick={() => dispatch(addFocusedVarieties([selectedFeature.group]))}
        >
          <i className="fa fa-plus"></i> {selectedFeature.group}
        </Badge>
      )}
      <div className="controls">
        <Button
          outline
          className="control-btn icon"
          onClick={clearList}
          disabled={focusedVarieties.length < 1}
          data-tooltip-id="tooltip"
          data-tooltip-content="Remove all genotypes except favorites"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          <span className="buttontext">
            <i className="fa fa-trash" />
          </span>
        </Button>
        <Button
          outline
          className="control-btn icon"
          onClick={clearVisualisation}
          disabled={visualizedVarieties.length < 1}
          data-tooltip-id="tooltip"
          data-tooltip-content="Clear thumbnail views"
          data-tooltip-place="top"
          data-tooltip-variant="dark"
        >
          <span className="buttontext">
            <i className="fa fa-eye-slash"></i>
          </span>
        </Button>
        {userCanRatePlots && (
          <Button
            className="control-btn"
            style={{ backgroundColor: HIPHEN_GREEN, border: "none" }}
            disabled={focusedVarieties.length < 1}
            onClick={openPlotRatingModal}
          >
            <span className="buttontext">
              <i className="fa fa-pencil" /> Rate plots
            </span>
          </Button>
        )}
        <Input
          className="search-property"
          bsSize="sm"
          placeholder="Search property"
          value={propertyFilter}
          disabled={visualizedVarieties.length === 0}
          onChange={(event) => setPropertyFilter(event.target.value)}
        />
      </div>
    </div>
  );
};

FocusList.propTypes = {
  groups: PropTypes.array.isRequired,
  getColor: PropTypes.func.isRequired,
  expandFocusView: PropTypes.bool.isRequired,
  setExpandFocusView: PropTypes.func.isRequired,
  propertyFilter: PropTypes.string.isRequired,
  setPropertyFilter: PropTypes.func.isRequired,
  selectedFeature: PropTypes.object,
  openPlotRatingModal: PropTypes.func.isRequired,
  userCanRatePlots: PropTypes.bool.isRequired,
};
