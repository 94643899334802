import { Col, Container } from "reactstrap";

import AlertComponent from "../components/alert";
import { Component } from "react";
import NavbarComponent from "../components/navbar";
import { PlotView } from "./PlotView";
import WorldMap from "./worldmap";
import { connect } from "react-redux";
import { fetchResultMapFeatures } from "../actions/resultMap";
import { selectFilteredFeatures } from "../selectors/resultMap";

class TrialDataviz extends Component {
  componentDidMount() {
    if (this.props.trialId) {
      const trial_selected = this.props.user.trials.find(
        (t) => t.id === this.props.trialId
      );
      if (trial_selected) {
        const dateToFetch =
          this.props.date != null &&
          trial_selected.trial_dates.includes(this.props.date)
            ? this.props.date
            : trial_selected.trial_dates[trial_selected.trial_dates.length - 1];
        this.props.fetchResultMapFeatures(trial_selected, dateToFetch);
      } else {
        this.props.history.push("/map");
      }
    } else {
      this.props.history.push("/map");
    }
  }

  componentDidUpdate() {
    // URL Rewriting
    if (this.props.resultMap.trial && !this.props.resultMap.refreshing)
      window.history.replaceState(
        {},
        "",
        `/map/${this.props.resultMap.trial.id}/${this.props.resultMap.trial_date}`
      );
  }

  render() {
    const selectedFeature = this.props.filteredFeatures.find(
      ({ id }) => id === this.props.resultMap.selectedPlotId
    );

    return (
      <div className="wrapper">
        <NavbarComponent />
        <AlertComponent />

        <Container fluid className="d-flex flex-row">
          <Col className="position-relative">
            <WorldMap zoom={3} expand />
          </Col>
          {selectedFeature && (
            <Col xs={3} className="p-0 position-relative">
              {/* display details on the selected microplot */}
              <PlotView
                trial={this.props.resultMap.trial}
                feature={selectedFeature}
                traitsList={this.props.resultMap.traitsListForMap}
                trial_date={this.props.resultMap.trial_date}
              />
            </Col>
          )}
        </Container>
      </div>
    );
  }
}

const callbacks = {
  fetchResultMapFeatures,
};

function mapStateToProps(state, props) {
  const { trialId, date } = props.match.params;
  const filteredFeatures = selectFilteredFeatures(state);
  return {
    user: state.user,
    trialId: trialId,
    date: date,
    resultMap: state.resultMap,
    filteredFeatures,
  };
}
export default connect(mapStateToProps, callbacks)(TrialDataviz);
