/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-fragments */
import "./trialdataviz.css";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import "react-leaflet-markercluster/dist/styles.min.css";

import { Button, Col, Row } from "reactstrap";
import {
  GeoJSON,
  Map,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  WMSTileLayer,
} from "react-leaflet";
import React, { Component } from "react";
import {
  fetchResultMapFeatures,
  resetResultMapFeatures,
  setSelectedContract,
  setSelectedPlotId,
} from "../actions/resultMap";
import { hasAnalyticsRole, hasExperimentsRole } from "../users/rolesUtil";
import {
  selectColorScale,
  selectFilteredFeatures,
  selectSelectedTrialContractInfo,
} from "../selectors/resultMap";

import { ANALYTICS_EVENTS } from "../constants";
import Control from "react-leaflet-control";
import CustomLegend from "./customLegend";
import DraggableMarker from "../drawingTools/draggableMarker";
import ExperimentTools from "../drawingTools/ExperimentTools";
import LegendColorScale from "./legendColorScale";
import LinearRuler from "../drawingTools/linearRuler";
import LoadingImg from "../components/loading";
import { MapNavbar } from "./MapNavbar";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { NULL_GROUP_LABEL } from "../powerdash/constants";
import { OrthoSelector } from "./OrthoSelector";
import PropTypes from "prop-types";
import { SelectSearch } from "../components/selectSearch";
import { Timeline } from "../powerdash/analyticsDashboard/components/timeline/timeline";
import { TrackingContext } from "../analytics";
import { TraitSelector } from "../powerdash/analyticsDashboard/components/traitSelector/traitSelector";
import _ from "lodash";
import { boolStringToInt } from "../services/utils";
import { connect } from "react-redux";
import { fetchUserContractsTrials } from "../actions/user";
import mapbg from "./mapbg.png";
import { truncateNumber } from "../services/utils";
import { withRouter } from "react-router-dom";

const FEATURE_DEFAULT_STYLE = {
  fillOpacity: 0.7,
  weight: 0.2,
  opacity: 1,
  color: "white",
};

const FEATURE_WITH_NO_TRAIT_STYLE = {
  weight: 3,
  opacity: 1,
  color: "skyblue",
  fillOpacity: 0,
};

const FEATURE_SELECTED_DEFAULT_STYLE = {
  weight: 3,
  color: "#184456",
  dashArray: "",
  fillOpacity: 0.7,
};

const SATELLITE_DEFAULT_STYLE = {
  weight: 2,
  opacity: 0.5,
  color: "white",
  fillOpacity: 0,
};

class WorldMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResultDownloadOpenedModal: false,
      initBounds: true,
      selectedOrtho: null,
      modeActivated: false,
      draggableMarkerActivate: false,
    };
    this.onSelectTrial = this.onSelectTrial.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onSelectContract = this.onSelectContract.bind(this);
    this.onBackToMarkerView = this.onBackToMarkerView.bind(this);
  }

  //Ref to the leaflet Map component
  map = React.createRef();
  //Ref to the leaflet features layer component
  geojson_layer = React.createRef();
  //Ref to the Ruler Tool component
  linearRuler = React.createRef();

  // a new geojson have been selected and we need to fit new geojson bounds
  need_to_fit_geojson = true;

  static contextType = TrackingContext;

  componentDidMount() {
    this.props.fetchUserContractsTrials();
    if (
      this.props.resultMap.refreshing ||
      !_.isEmpty(this.props.resultMap.features) ||
      !_.isEmpty(this.props.orthos)
    )
      this.need_to_fit_geojson = true;
  }

  componentDidUpdate() {
    const { trackEvent } = this.context;
    this.map.current.leafletElement.invalidateSize();

    // Zoom on selected trial
    if (
      (!_.isEmpty(this.props.resultMap.features) ||
        !_.isEmpty(this.props.orthos)) &&
      !this.props.resultMap.refreshing &&
      this.need_to_fit_geojson
    ) {
      this.onFitZoomToSite();
      this.need_to_fit_geojson = false;
      if (!this.props.contextIsAnalytics)
        trackEvent(ANALYTICS_EVENTS.MAP_IS_BEING_RENDERED);
    }

    if (this.props.externalTrialSelected) {
      this.onSelectTrial(this.props.externalTrialSelected);
      this.props.setExternalTrialSelected(null);
    }
  }

  async onSelectTrial(trial_selected) {
    if (
      trial_selected &&
      trial_selected.trial_dates[0] &&
      !this.props.resultMap.refreshing
    ) {
      await this.props.fetchResultMapFeatures(
        trial_selected,
        trial_selected.trial_dates[trial_selected.trial_dates.length - 1]
      );
      this.need_to_fit_geojson = true;
      //Deactivate ruler mode if it is activated
      if (this.linearRuler.current) this.linearRuler.current.resetRuler();

      //reset selected ortho
      this.setState({
        selectedOrtho: null,
        draggableMarkerActivate: false,
      });
    }
  }

  onChangeDate(selectedDate) {
    //refetch without refreshing experiments
    this.props.fetchResultMapFeatures(
      this.props.resultMap.trial,
      selectedDate,
      false
    );
    //reset selected ortho
    if (this.state.selectedOrtho) {
      const reset_ortho_selected = _.get(
        this.props.resultMap.trial,
        `ortho_layer[${selectedDate}][${this.state.selectedOrtho}]`,
        true
      );
      if (reset_ortho_selected === true)
        this.setState({
          selectedOrtho: null,
        });
    }
  }

  changeSelectedOrtho(ortho) {
    this.setState({
      selectedOrtho: ortho,
    });
  }

  modeActivation(activate) {
    this.setState({ modeActivated: activate });
  }

  getFeatureStyle(feature) {
    if (this.props.contractInfo.system === "satellite")
      return SATELLITE_DEFAULT_STYLE;

    if (!feature.trait_list.length) return FEATURE_WITH_NO_TRAIT_STYLE;

    return {
      ...FEATURE_DEFAULT_STYLE,
      fillOpacity: this.props.resultMap.opacity,
      fillColor: this.props.colorScale.func(
        boolStringToInt(
          feature.properties[this.props.resultMap.selectedTrait.technical_name]
        )
      ),
    };
  }

  onFitZoomToSite() {
    if (this.props.resultMap.trial.bounding_box) {
      //zoom on site bouding box if no result
      this.map.current.leafletElement.fitBounds(
        this.props.resultMap.trial.bounding_box
      );
    } else if (this.geojson_layer.current) {
      // zoom on features if there is results
      this.map.current.leafletElement.fitBounds(
        this.geojson_layer.current.leafletElement.getBounds()
      );
    } else {
      // Prevent app from crashing when no features and bounding_box is null
      console.warn("No ortho and no result available.");
      this.onBackToMarkerView();
    }
  }

  onBackToMarkerView() {
    this.props.resetResultMapFeatures();
    this.props.history.push("/map");
    window.location.reload();
  }

  onSelectContract(contract) {
    this.props.setSelectedContract(contract);
  }

  // Content to display on map to help user to select a trial
  TrialSelectionDisplay() {
    const trials = (
      this.props.contextIsAnalytics
        ? this.props.user.trials.filter((trial) =>
            hasAnalyticsRole(
              this.props.user.contracts?.find(
                ({ id }) => id === trial.contract_id
              )?.roles
            )
          )
        : this.props.user.trials
    ).filter((trial) =>
      this.props.resultMap.selectedContract
        ? this.props.resultMap.selectedContract.sites
            .map(({ id }) => id)
            .includes(trial.id)
        : true
    );

    return (
      <MarkerClusterGroup
        ref="markercluster"
        showCoverageOnHover={false}
        maxClusterRadius={30}
      >
        {trials.map((trial) => (
          <Marker
            key={trial.id}
            position={trial.trial_position}
            onClick={(e) => this.onSelectTrial(trial)}
          >
            <Tooltip>
              <div>
                <b>{trial.display_name}</b>
              </div>
            </Tooltip>
            <Popup>
              <div>
                <b>{trial.display_name} loading</b>
              </div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    );
  }

  // function bind to each microplot on hover and on click
  onEachFeature = (feature, layer) => {
    layer.bindTooltip(
      "<div><b>Plot id: </b> " +
        feature.displayId +
        "</div>" +
        (this.props.resultMap.selectedTrait.name
          ? "<div><b>" +
            this.props.resultMap.selectedTrait.name +
            "</b>: " +
            truncateNumber(
              feature.properties[
                this.props.resultMap.selectedTrait.technical_name
              ] ?? NULL_GROUP_LABEL
            ) +
            "</div>"
          : "")
    );

    if (this.props.resultMap.selectedPlotId)
      if (layer.feature.id === this.props.resultMap.selectedPlotId) {
        layer.bringToFront();
        layer.setStyle({ ...FEATURE_SELECTED_DEFAULT_STYLE });
      }

    layer.on({
      click: () => {
        if (this.linearRuler.current.isActive()) return;
        this.props.setSelectedPlotId(feature.id);
      },
    });
  };

  displayWMS() {
    if (_.isEmpty(this.props.orthos)) return "";

    const default_ortho =
      this.props.orthos.plain ?? Object.values(this.props.orthos)[0];
    const displayed_ortho = this.state.selectedOrtho
      ? this.props.orthos[this.state.selectedOrtho]
      : default_ortho;
    return (
      <WMSTileLayer
        layers={displayed_ortho}
        ref="wmsTileLayer"
        url={process.env.REACT_APP_GEOSERVER_URL}
        format="image/png"
        transparent="true"
        opacity="1"
        tiled={true}
      />
    );
  }

  // generates unique identity for each feature
  getIdentity(feature) {
    return feature.id;
  }

  // Content to display on map once user selected a trial
  TrialDisplay() {
    return (
      <React.Fragment>
        {/* Display orthoimage */}
        {this.displayWMS()}

        {/* Display microplot boundaries with colors*/}
        {!_.isEmpty(this.props.resultMap.features) && (
          <GeoJSON
            key={new Date().getTime()}
            ref={this.geojson_layer}
            data={this.props.filteredFeatures}
            style={(feature) => this.getFeatureStyle(feature)}
            onEachFeature={(layer, feature) =>
              this.onEachFeature(layer, feature)
            }
          />
        )}

        {!this.props.hideHud && (
          <Control position="bottomleft">
            <div className="bottomleft">
              {/* Select orthoimage */}
              {Object.keys(this.props.orthos).length > 1 && (
                <OrthoSelector
                  refreshing={this.props.resultMap.refreshing}
                  orthos={Object.keys(this.props.orthos)}
                  selectedOrtho={
                    this.state.selectedOrtho
                      ? this.state.selectedOrtho
                      : (this.props.orthos.plain && "plain") ??
                        Object.keys(this.props.orthos)[0]
                  }
                  changeSelectedOrtho={(ortho) =>
                    this.changeSelectedOrtho(ortho)
                  }
                />
              )}

              {/* select a trait */}
              <TraitSelector onMap />
              {_.get(this.props.resultMap.selectedTrait, "style.opacity") ===
                true && (
                <LegendColorScale
                  opacity={this.props.resultMap.opacity}
                  changeSelectedTrait={(trait) =>
                    this.changeSelectedTrait(trait)
                  }
                />
              )}
              {this.props.contractInfo?.system === "satellite" && (
                <CustomLegend />
              )}
            </div>
          </Control>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        <Row
          className={`map-controls ${
            this.props.contextIsAnalytics ? "g-0" : ""
          }`}
        >
          <Col xs="2" className="p-1 map-controls-item">
            {!this.props.hideNavbar && (
              <MapNavbar
                contractInfo={this.props.contractInfo}
                modeActivated={this.state.modeActivated}
                onSelectTrial={this.onSelectTrial}
                selectedContract={null}
                onSelectContract={this.onSelectContract}
                onBackToMarkerView={this.onBackToMarkerView}
              />
            )}
          </Col>
          {(!_.isEmpty(this.props.resultMap.features) ||
            !_.isEmpty(this.props.orthos)) && (
            <>
              <Col xs="8" className="p-1 map-controls-item">
                {!this.props.hideNavbar && (
                  <div>
                    <Timeline
                      className="map-timeline"
                      dates={this.props.resultMap.trial.trial_dates}
                      selectedDate={this.props.resultMap.trial_date}
                      onDateChange={this.onChangeDate}
                    />
                  </div>
                )}
              </Col>
              <Col
                xs="2"
                className="p-1 map-searchbar-wrapper map-controls-item"
              >
                {!this.props.hideHud &&
                  this.props.resultMap.features.length > 0 && (
                    <SelectSearch
                      className="map-searchbar"
                      placeholder="Search plot id"
                      options={this.props.filteredFeatures.map((feature) => {
                        return {
                          label: feature.displayId,
                          value: feature.displayId,
                        };
                      })}
                      onChange={({ value }) => {
                        const feature = this.props.resultMap.features?.find(
                          ({ displayId }) => displayId === value
                        );
                        if (feature) this.props.setSelectedPlotId(feature.id);
                      }}
                    />
                  )}
              </Col>
            </>
          )}
        </Row>

        <div className="row h-100">
          <Map
            ref={this.map}
            attributionControl={!this.props.hideHud}
            className="col-12"
            center={[26.0, 30.0]}
            zoom={this.props.zoom}
            minZoom={this.props.zoom}
            maxZoom={30}
            zoomControl={false}
            maxBounds={[
              [-85, -180],
              [85, 180],
            ]}
            maxBoundsViscosity={1.0}
            noWrap={true}
            style={{
              backgroundImage: `url(${mapbg})`,
              backgroundSize: "1300px",
            }}
          >
            {!this.props.hideNavbar && this.props.resultMap.selectedPlotId && (
              <div
                className="drawer-button clickable"
                onClick={() => this.props.setSelectedPlotId(null)}
              >
                <i className="fa fa-lg fa-chevron-right" />
              </div>
            )}
            {_.isEmpty(this.props.resultMap.features) &&
              !_.isEmpty(this.props.orthos) &&
              this.props.contractInfo.has_experiment &&
              this.props.displayExperimentTool && (
                <Control position="topright">
                  <ExperimentTools
                    map={this.map.current?.leafletElement}
                    modeActivation={(v) => this.modeActivation(v)}
                    linearRulerRef={this.linearRuler.current}
                  />
                </Control>
              )}

            {!this.props.hideHud && (
              <Control position="bottomright" className="text-end">
                {(!_.isEmpty(this.props.resultMap.features) ||
                  !_.isEmpty(this.props.orthos)) && (
                  <>
                    <Button
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Fit zoom to the entire site"
                      className="btn btn-hiphen"
                      onClick={() => this.onFitZoomToSite()}
                    >
                      <i className="map_focus_icon" />
                    </Button>
                  </>
                )}

                {(!_.isEmpty(this.props.resultMap.features) ||
                  !_.isEmpty(this.props.orthos)) &&
                  !this.state.modeActivated && (
                    <LinearRuler
                      map={this.map.current?.leafletElement}
                      unitSystem="metric"
                      color="#fff"
                      type="line"
                      position="bottomright"
                      doubleClickSpeed={100}
                      ref={this.linearRuler}
                    />
                  )}

                {(!_.isEmpty(this.props.resultMap.features) ||
                  !_.isEmpty(this.props.orthos)) && (
                  <DraggableMarker
                    map={this.map.current?.leafletElement}
                    isActive={this.state.draggableMarkerActivate}
                    setIsActive={(active) =>
                      this.setState({
                        draggableMarkerActivate: active,
                      })
                    }
                  />
                )}
              </Control>
            )}

            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Powered by Esri"
              maxZoom="18"
            />
            <TileLayer
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FzdWJvbG8iLCJhIjoiY2tjdzRvbW8yMGFuZDMwczF6NmZkcDM2NSJ9.EhB2o6-Gz74dGmQwCXTVVA"
              attribution="
              <a href='https://www.mapbox.com/about/maps/'><code>© Mapbox</code></a>
              <a href='http://www.openstreetmap.org/about/'><code>© OpenStreetMap</code></a>
              <a href='https://www.mapbox.com/map-feedback/'><code>Improve this map</code></a>"
              minZoom="19"
            />

            {/* Switch between cluster and trial display when user select a marker/trial */}
            {!_.isEmpty(this.props.resultMap.features) ||
            !_.isEmpty(this.props.orthos)
              ? this.TrialDisplay()
              : this.TrialSelectionDisplay()}
          </Map>
        </div>

        {this.props.resultMap.refreshing && !this.props.contextIsAnalytics && (
          <div className="fullscreen_popup">
            <LoadingImg visible={this.props.resultMap.refreshing} />
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const filteredFeatures = selectFilteredFeatures(state);
  const contractInfo = selectSelectedTrialContractInfo(state);
  const colorScale = selectColorScale(state);

  const orthos = _.get(
    state.resultMap.trial,
    `ortho_layer[${state.resultMap.trial_date}]`,
    {}
  );

  const displayExperimentTool = hasExperimentsRole(contractInfo?.roles);

  return {
    contractInfo,
    user: state.user,
    resultMap: state.resultMap,
    orthos,
    displayExperimentTool,
    filteredFeatures,
    colorScale,
  };
}

const callbacks = {
  fetchUserContractsTrials,
  fetchResultMapFeatures,
  resetResultMapFeatures,
  setSelectedContract,
  setSelectedPlotId,
};

export default withRouter(connect(mapStateToProps, callbacks)(WorldMap));

WorldMap.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserContractsTrials: PropTypes.func.isRequired,
  fetchResultMapFeatures: PropTypes.func.isRequired,
  resetResultMapFeatures: PropTypes.func.isRequired,
  contractInfo: PropTypes.object,
  resultMap: PropTypes.object.isRequired,
  orthos: PropTypes.object.isRequired,
  displayExperimentTool: PropTypes.bool.isRequired,
  expand: PropTypes.bool,
  filteredFeatures: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorScale: PropTypes.object.isRequired,
  setSelectedContract: PropTypes.func.isRequired,
  setSelectedPlotId: PropTypes.func.isRequired,
};
